export const summaryPageTopMenu = {
	intro: { id: 'intro', text: 'Intro', path: '#intro', icon: 'Vrpano', subMenu: null },
	bootstrap: {
		id: 'bootstrap',
		text: 'Bootstrap Components',
		path: '#bootstrap',
		icon: 'BootstrapFill',
		subMenu: null,
	},
	storybook: {
		id: 'storybook',
		text: 'Storybook',
		path: '#storybook',
		icon: 'CustomStorybook',
		subMenu: null,
	},
	formik: {
		id: 'formik',
		text: 'Formik',
		path: '#formik',
		icon: 'CheckBox',
		subMenu: null,
	},
	apex: {
		id: 'apex',
		text: 'Apex Charts',
		path: '#apex',
		icon: 'AreaChart',
		subMenu: null,
	},
};

export const dashboardPagesMenu = {
	evohunt: {
		id: 'evohunt',
		text: 'Landing',
		path: '/',
		icon: 'Dashboard',
		subMenu: null,
	},
	companyPreview: {
		id: 'companyPreview',
		text: 'Company Preview',
		path: '/preview',
		icon: 'Dashboard',
		subMenu: null,
	},
	candidatePreview: {
		id: 'candidatePreview',
		text: 'Candidate Preview',
		path: '/candidate-preview',
		icon: 'Dashboard',
		subMenu: null,
	},
	ideaPreview: {
		id: 'ideaPreview',
		text: 'Idea Preview',
		path: '/idea-preview',
		icon: 'Dashboard',
		subMenu: null,
	},
	dashboard: {
		id: 'dashboard',
		text: 'Dashboard',
		path: '/',
		icon: 'Dashboard',
		subMenu: null,
	},
	dashboardBooking: {
		id: 'dashboard-booking',
		text: 'Dashboard Booking',
		path: 'dashboard-booking',
		icon: 'emoji_transportation',
		subMenu: null,
	},
	crmDashboard: {
		id: 'crmDashboard',
		text: 'CRM Dashboard',
		path: 'crm/dashboard',
		icon: 'RecentActors',
	},
	summary: {
		id: 'summary',
		text: 'Summary',
		path: 'summary',
		icon: 'sticky_note_2',
		subMenu: null,
	},
};

export const demoPagesMenu = {
	pages: {
		id: 'pages',
		text: 'Pages',
		icon: 'Extension',
	},
	listPages: {
		id: 'listPages',
		text: 'List Pages',
		path: 'list-pages',
		icon: 'Dvr',
		subMenu: {
			listBoxed: {
				id: 'listBoxed',
				text: 'Boxed List',
				path: 'list-pages/boxed-list',
				icon: 'ViewArray',
			},
			listFluid: {
				id: 'listFluid',
				text: 'Fluid List',
				path: 'list-pages/fluid-list',
				icon: 'ViewDay',
			},
		},
	},
	gridPages: {
		id: 'gridPages',
		text: 'Grid Pages',
		path: 'grid-pages',
		icon: 'Window',
		subMenu: {
			gridBoxed: {
				id: 'gridBoxed',
				text: 'Boxed Grid',
				path: 'grid-pages/boxed',
				icon: 'ViewArray',
			},
			gridFluid: {
				id: 'gridFluid',
				text: 'Fluid Grid',
				path: 'grid-pages/fluid',
				icon: 'ViewDay',
			},
		},
	},
	editPages: {
		id: 'editPages',
		text: 'Edit Pages',
		path: 'edit-pages',
		icon: 'drive_file_rename_outline ',
		subMenu: {
			editModern: {
				id: 'editModern',
				text: 'Modern Edit',
				path: 'edit-pages/modern',
				icon: 'AutoAwesomeMosaic',
				notification: 'primary',
			},
			editBoxed: {
				id: 'editBoxed',
				text: 'Boxed Edit',
				path: 'edit-pages/boxed',
				icon: 'ViewArray',
			},
			editFluid: {
				id: 'editFluid',
				text: 'Fluid Edit',
				path: 'edit-pages/fluid',
				icon: 'ViewDay',
			},
			editWizard: {
				id: 'editWizard',
				text: 'Wizard Edit',
				path: 'edit-pages/wizard',
				icon: 'LinearScale',
			},
			editInCanvas: {
				id: 'editInCanvas',
				text: 'In Canvas Edit',
				path: 'edit-pages/in-canvas',
				icon: 'VerticalSplit',
			},
			editInModal: {
				id: 'editInModal',
				text: 'In Modal Edit',
				path: 'edit-pages/in-modal',
				icon: 'PictureInPicture',
			},
		},
	},
	singlePages: {
		id: 'singlePages',
		text: 'Single Pages',
		path: 'single-pages',
		icon: 'Article',
		subMenu: {
			boxedSingle: {
				id: 'boxedSingle',
				text: 'Boxed',
				path: 'single-pages/boxed',
				icon: 'ViewArray',
			},
			fluidSingle: {
				id: 'fluidSingle',
				text: 'Fluid',
				path: 'single-pages/fluid',
				icon: 'ViewDay',
			},
		},
	},
	pricingTable: {
		id: 'pricingTable',
		text: 'Pricing Table',
		path: 'pricing-table',
		icon: 'Local Offer',
	},

	auth: {
		id: 'auth',
		text: 'Auth Pages',
		icon: 'Extension',
	},
	login: {
		id: 'login',
		text: 'Login',
		path: 'login',
		icon: 'Login',
	},
	signUp: {
		id: 'signUp',
		text: 'Sign Up',
		path: 'auth-pages/sign-up',
		icon: 'PersonAdd',
	},

	page404: {
		id: 'Page404',
		text: '404 Page',
		path: 'auth-pages/404',
		icon: 'ReportGmailerrorred',
	},

	app: {
		id: 'app',
		text: 'Apps',
		icon: 'Extension',
	},
	projectManagement: {
		id: 'projectManagement',
		text: 'Project Management',
		path: 'project-management',
		icon: 'AutoStories',
		subMenu: {
			list: {
				id: 'list',
				text: 'Projects',
				path: 'project-management/list',
				icon: 'AutoStories',
			},
			itemID: {
				id: 'projectID',
				text: 'projectID',
				path: 'project-management/project',
				hide: true,
			},
			item: {
				id: 'item',
				text: 'Project',
				path: 'project-management/project/1',
				icon: 'Book',
			},
		},
	},
	knowledge: {
		id: 'knowledge',
		text: 'Knowledge',
		path: 'knowledge',
		icon: 'AutoStories',
		subMenu: {
			grid: {
				id: 'grid',
				text: 'Knowledge Grid',
				path: 'knowledge/grid',
				icon: 'AutoStories',
			},
			itemID: {
				id: 'itemID',
				text: 'itemID',
				path: 'knowledge/item',
				hide: true,
			},
			item: {
				id: 'item',
				text: 'Item',
				path: 'knowledge/item/1',
				icon: 'Book',
			},
		},
	},
	sales: {
		id: 'sales',
		text: 'Sales',
		path: 'sales',
		icon: 'Store',
		subMenu: {
			dashboard: dashboardPagesMenu.dashboard,
			salesList: {
				id: 'products',
				text: 'Sales List',
				path: 'sales/sales-list',
				icon: 'FactCheck',
			},
			productsGrid: {
				id: 'productsGrid',
				text: 'Products Grid',
				path: 'sales/grid',
				icon: 'CalendarViewMonth',
			},
			productID: {
				id: 'productID',
				text: 'productID',
				path: 'sales/product',
				hide: true,
			},
			product: {
				id: 'product',
				text: 'Product',
				path: 'sales/product/1',
				icon: 'QrCode2',
			},
			transactions: {
				id: 'transactions',
				text: 'Transactions',
				path: 'sales/transactions',
				icon: 'PublishedWithChanges',
			},
		},
	},
	appointment: {
		id: 'appointment',
		text: 'Appointment',
		path: 'appointment',
		icon: 'Today',
		subMenu: {
			dashboard: dashboardPagesMenu.dashboardBooking,
			calendar: {
				id: 'calendar',
				text: 'Calendar',
				path: 'appointment/calendar',
				icon: 'EditCalendar',
				notification: true,
			},
			employeeList: {
				id: 'employeeList',
				text: 'Employee List',
				path: 'appointment/employee-list',
				icon: 'PersonSearch',
			},
			employeeID: {
				id: 'employeeID',
				text: 'employeeID',
				path: 'appointment/employee',
				hide: true,
			},
			employee: {
				id: 'employee',
				text: 'Employee',
				path: 'appointment/employee/1',
				icon: 'QrCode2',
			},
			appointmentList: {
				id: 'appointmentList',
				text: 'Appointment List',
				path: 'appointment/appointment-list',
				icon: 'Event',
			},
		},
	},
	crm: {
		id: 'crm',
		text: 'CRM',
		path: 'crm',
		icon: 'Contacts',
		subMenu: {
			dashboard: {
				id: 'dashboard',
				text: 'CRM Dashboard',
				path: 'crm/dashboard',
				icon: 'RecentActors',
			},
			customersList: {
				id: 'customersList',
				text: 'Customers',
				path: 'crm/customers',
				icon: 'PersonSearch',
			},
			customerID: {
				id: 'customerID',
				text: 'customerID',
				path: 'crm/customer',
				hide: true,
			},
			customer: {
				id: 'customer',
				text: 'Customer',
				path: 'crm/customer/1',
				icon: 'Badge',
			},
			// sales: {
			// 	id: 'sales',
			// 	text: 'Sales',
			// 	path: 'crm/sales',
			// 	icon: 'Storefront',
			// },
			// invoiceID: {
			// 	id: 'invoiceID',
			// 	text: 'invoiceID',
			// 	path: 'crm/invoice',
			// 	hide: true,
			// },
			// invoice: {
			// 	id: 'invoice',
			// 	text: 'Invoice',
			// 	path: 'crm/invoice/1',
			// 	icon: 'Receipt',
			// },
		},
	},
	chat: {
		id: 'chat',
		text: 'Chat',
		path: 'chat',
		icon: 'Forum',
		subMenu: {
			withListChat: {
				id: 'withListChat',
				text: 'With List',
				path: 'chat/with-list',
				icon: 'Quickreply',
			},
			onlyListChat: {
				id: 'onlyListChat',
				text: 'Only List',
				path: 'chat/only-list',
				icon: 'Dns',
			},
		},
	},
};

export const pageLayoutTypesPagesMenu = {
	layoutTypes: {
		id: 'layoutTypes',
		text: 'Page Layout Types',
	},
	blank: {
		id: 'blank',
		text: 'Blank',
		path: 'page-layouts/blank',
		icon: 'check_box_outline_blank ',
	},
	pageLayout: {
		id: 'pageLayout',
		text: 'Page Layout',
		path: 'page-layouts',
		icon: 'BackupTable',
		subMenu: {
			headerAndSubheader: {
				id: 'headerAndSubheader',
				text: 'Header & Subheader',
				path: 'page-layouts/header-and-subheader',
				icon: 'ViewAgenda',
			},
			onlyHeader: {
				id: 'onlyHeader',
				text: 'Only Header',
				path: 'page-layouts/only-header',
				icon: 'ViewStream',
			},
			onlySubheader: {
				id: 'onlySubheader',
				text: 'Only Subheader',
				path: 'page-layouts/only-subheader',
				icon: 'ViewStream',
			},
			onlyContent: {
				id: 'onlyContent',
				text: 'Only Content',
				path: 'page-layouts/only-content',
				icon: 'WebAsset',
			},
		},
	},
	asideTypes: {
		id: 'asideTypes',
		text: 'Aside Types',
		path: 'aside-types',
		icon: 'Vertical Split',
		subMenu: {
			defaultAside: {
				id: 'defaultAside',
				text: 'Default Aside',
				path: 'aside-types/default-aside',
				icon: 'ViewQuilt',
			},
			minimizeAside: {
				id: 'minimizeAside',
				text: 'Minimize Aside',
				path: 'aside-types/minimize-aside',
				icon: 'View Compact',
			},
		},
	},
};

export const gettingStartedPagesMenu = {
	gettingStarted: {
		id: 'gettingStarted',
		text: 'Getting Started',
		path: 'getting-started',
		icon: 'Book',
		subMenu: {
			installation: {
				id: 'installation',
				text: 'Installation',
				path: 'getting-started/installation',
				icon: 'BuildCircle',
			},
			dev: {
				id: 'dev',
				text: 'Development',
				path: 'getting-started/development',
				icon: 'DirectionsRun',
			},
			folderStructure: {
				id: 'folderStructure',
				text: 'Folder Structure',
				path: 'getting-started/folder-structure',
				icon: 'SnippetFolder',
			},
			bootstrapVariables: {
				id: 'bootstrapVariables',
				text: 'Bootstrap Variables',
				path: 'getting-started/bootstrap-variables',
				icon: 'SnippetFolder',
			},
			projectStructure: {
				id: 'projectStructure',
				text: 'Project Structure',
				path: 'getting-started/project-structure',
				icon: 'SnippetFolder',
			},
		},
	},
	routes: {
		id: 'routes',
		text: 'Routes & Pages',
		path: 'routes',
		icon: 'AltRoute',
		subMenu: {
			router: {
				id: 'router',
				text: 'Router',
				path: 'routes/router',
				icon: 'Router',
			},
		},
	},
};

export const componentPagesMenu = {
	bootstrap: {
		id: 'bootstrap',
		text: 'Bootstrap',
		icon: 'Extension',
	},
	components: {
		id: 'components',
		text: 'Component',
		path: 'components',
		icon: 'Extension',
		notification: 'success',
		subMenu: {
			accordion: {
				id: 'accordion',
				text: 'Accordion',
				path: 'components/accordion',
				icon: 'ViewDay',
			},
			alert: {
				id: 'alert',
				text: 'Alert',
				path: 'components/alert',
				icon: 'Announcement',
			},
			badge: {
				id: 'badge',
				text: 'Badge',
				path: 'components/badge',
				icon: 'Vibration',
			},
			breadcrumb: {
				id: 'breadcrumb',
				text: 'Breadcrumb',
				path: 'components/breadcrumb',
				icon: 'AddRoad',
			},
			button: {
				id: 'button',
				text: 'Button',
				path: 'components/button',
				icon: 'SmartButton',
			},
			buttonGroup: {
				id: 'buttonGroup',
				text: 'Button Group',
				path: 'components/button-group',
				icon: 'Splitscreen',
			},
			card: {
				id: 'card',
				text: 'Card',
				path: 'components/card',
				icon: 'Crop32',
			},
			carousel: {
				id: 'carousel',
				text: 'Carousel',
				path: 'components/carousel',
				icon: 'RecentActors',
			},
			// Close
			collapse: {
				id: 'collapse',
				text: 'Collapse',
				path: 'components/collapse',
				icon: 'UnfoldLess',
			},
			dropdowns: {
				id: 'dropdowns',
				text: 'Dropdowns',
				path: 'components/dropdowns',
				icon: 'Inventory',
			},
			listGroup: {
				id: 'listGroup',
				text: 'List Group',
				path: 'components/list-group',
				icon: 'ListAlt',
			},
			modal: {
				id: 'modal',
				text: 'Modal',
				path: 'components/modal',
				icon: 'PictureInPicture',
			},
			navsTabs: {
				id: 'navsTabs',
				text: 'Navs & Tabs',
				path: 'components/navs-and-tabs',
				icon: 'PivotTableChart',
			},
			// Navbar
			offcanvas: {
				id: 'offcanvas',
				text: 'Offcanvas',
				path: 'components/offcanvas',
				icon: 'VerticalSplit',
			},
			pagination: {
				id: 'pagination',
				text: 'Pagination',
				path: 'components/pagination',
				icon: 'Money',
			},
			popovers: {
				id: 'popovers',
				text: 'Popovers',
				path: 'components/popovers',
				icon: 'Assistant',
			},
			progress: {
				id: 'progress',
				text: 'Progress',
				path: 'components/progress',
				icon: 'HourglassTop',
			},
			scrollspy: {
				id: 'scrollspy',
				text: 'Scrollspy',
				path: 'components/scrollspy',
				icon: 'KeyboardHide',
			},
			spinners: {
				id: 'spinners',
				text: 'Spinners',
				path: 'components/spinners',
				icon: 'RotateRight',
			},
			table: {
				id: 'table',
				text: 'Table',
				path: 'components/table',
				icon: 'TableChart',
			},
			toasts: {
				id: 'toasts',
				text: 'Toasts',
				path: 'components/toasts',
				icon: 'RotateRight',
			},
			tooltip: {
				id: 'tooltip',
				text: 'Tooltip',
				path: 'components/tooltip',
				icon: 'Assistant',
			},
		},
	},
	forms: {
		id: 'forms',
		text: 'Forms',
		path: 'forms',
		icon: 'CheckBox',
		notification: 'success',
		subMenu: {
			formGroup: {
				id: 'formGroup',
				text: 'Form Group',
				path: 'forms/form-group',
				icon: 'Source',
			},
			formControl: {
				id: 'formControl',
				text: 'Form Controls',
				path: 'forms/form-controls',
				icon: 'Create',
			},
			select: {
				id: 'select',
				text: 'Select',
				path: 'forms/select',
				icon: 'Checklist',
			},
			checksAndRadio: {
				id: 'checksAndRadio',
				text: 'Checks & Radio',
				path: 'forms/checks-and-radio',
				icon: 'CheckBox',
			},
			range: {
				id: 'range',
				text: 'Range',
				path: 'forms/range',
				icon: 'HdrStrong',
			},
			inputGroup: {
				id: 'inputGroup',
				text: 'Input Group',
				path: 'forms/input-group',
				icon: 'PowerInput',
			},
			validation: {
				id: 'validation',
				text: 'Validation',
				path: 'forms/validation',
				icon: 'VerifiedUser',
			},
			wizard: {
				id: 'wizard',
				text: 'Wizard',
				path: 'forms/wizard',
				icon: 'LinearScale',
			},
		},
	},
	content: {
		id: 'content',
		text: 'Content',
		path: 'content',
		icon: 'format_size',
		subMenu: {
			typography: {
				id: 'typography',
				text: 'Typography',
				path: 'content/typography',
				icon: 'text_fields',
			},
			images: {
				id: 'images',
				text: 'Images',
				path: 'content/images',
				icon: 'Image ',
			},
			tables: {
				id: 'tables',
				text: 'Tables',
				path: 'content/tables',
				icon: 'table_chart',
			},
			figures: {
				id: 'figures',
				text: 'Figures',
				path: 'content/figures',
				icon: 'Photo Library ',
			},
		},
	},
	utilities: {
		id: 'utilities',
		text: 'Utilities',
		path: 'utilities',
		icon: 'Support',
		subMenu: {
			api: {
				id: 'api',
				text: 'API',
				path: 'utilities/api',
				icon: 'Api',
			},
			background: {
				id: 'background',
				text: 'Background',
				path: 'utilities/background',
				icon: 'FormatColorFill',
			},
			borders: {
				id: 'borders',
				text: 'Borders',
				path: 'utilities/borders',
				icon: 'BorderStyle',
			},
			colors: {
				id: 'colors',
				text: 'Colors',
				path: 'utilities/colors',
				icon: 'InvertColors',
			},
			display: {
				id: 'display',
				text: 'Display',
				path: 'utilities/display',
				icon: 'LaptopMac',
			},
			flex: {
				id: 'flex',
				text: 'Flex',
				path: 'utilities/flex',
				icon: 'SettingsOverscan',
			},
			float: {
				id: 'float',
				text: 'Float',
				path: 'utilities/float',
				icon: 'ViewArray',
			},
			interactions: {
				id: 'interactions',
				text: 'Interactions',
				path: 'utilities/interactions',
				icon: 'Mouse',
			},
			overflow: {
				id: 'overflow',
				text: 'Overflow',
				path: 'utilities/overflow',
				icon: 'TableRows',
			},
			position: {
				id: 'position',
				text: 'Position',
				path: 'utilities/position',
				icon: 'Adjust',
			},
			shadows: {
				id: 'shadows',
				text: 'Shadows',
				path: 'utilities/shadows',
				icon: 'ContentCopy',
			},
			sizing: {
				id: 'sizing',
				text: 'Sizing',
				path: 'utilities/sizing',
				icon: 'Straighten',
			},
			spacing: {
				id: 'spacing',
				text: 'Spacing',
				path: 'utilities/spacing',
				icon: 'SpaceBar',
			},
			text: {
				id: 'text',
				text: 'Text',
				path: 'utilities/text',
				icon: 'TextFields',
			},
			verticalAlign: {
				id: 'vertical-align',
				text: 'Vertical Align',
				path: 'utilities/vertical-align',
				icon: 'VerticalAlignCenter',
			},
			visibility: {
				id: 'visibility',
				text: 'Visibility',
				path: 'utilities/visibility',
				icon: 'Visibility',
			},
		},
	},
	extra: {
		id: 'extra',
		text: 'Extra Library',
		icon: 'Extension',
		path: undefined,
	},
	icons: {
		id: 'icons',
		text: 'Icons',
		path: 'icons',
		icon: 'Grain',
		notification: 'success',
		subMenu: {
			icon: {
				id: 'icon',
				text: 'Icon',
				path: 'icons/icon',
				icon: 'Lightbulb',
			},
			material: {
				id: 'material',
				text: 'Material',
				path: 'icons/material',
				icon: 'Verified',
			},
		},
	},
	charts: {
		id: 'charts',
		text: 'Charts',
		path: 'charts',
		icon: 'AreaChart',
		notification: 'success',
		subMenu: {
			chartsUsage: {
				id: 'chartsUsage',
				text: 'General Usage',
				path: 'charts/general-usage',
				icon: 'Description',
			},
			chartsSparkline: {
				id: 'chartsSparkline',
				text: 'Sparkline',
				path: 'charts/sparkline',
				icon: 'AddChart',
			},
			chartsLine: {
				id: 'chartsLine',
				text: 'Line',
				path: 'charts/line',
				icon: 'ShowChart',
			},
			chartsArea: {
				id: 'chartsArea',
				text: 'Area',
				path: 'charts/area',
				icon: 'AreaChart',
			},
			chartsColumn: {
				id: 'chartsColumn',
				text: 'Column',
				path: 'charts/column',
				icon: 'BarChart',
			},
			chartsBar: {
				id: 'chartsBar',
				text: 'Bar',
				path: 'charts/bar',
				icon: 'StackedBarChart',
			},
			chartsMixed: {
				id: 'chartsMixed',
				text: 'Mixed',
				path: 'charts/mixed',
				icon: 'MultilineChart',
			},
			chartsTimeline: {
				id: 'chartsTimeline',
				text: 'Timeline',
				path: 'charts/timeline',
				icon: 'WaterfallChart',
			},
			chartsCandleStick: {
				id: 'chartsCandleStick',
				text: 'Candlestick',
				path: 'charts/candlestick',
				icon: 'Cake',
			},
			chartsBoxWhisker: {
				id: 'chartsBoxWhisker',
				text: 'Box Whisker',
				path: 'charts/box-whisker',
				icon: 'SportsMma',
			},
			chartsPieDonut: {
				id: 'chartsPieDonut',
				text: 'Pie & Donut',
				path: 'charts/pie-donut',
				icon: 'PieChart',
			},
			chartsRadar: {
				id: 'chartsRadar',
				text: 'Radar',
				path: 'charts/radar',
				icon: 'BrightnessLow',
			},
			chartsPolar: {
				id: 'chartsPolar',
				text: 'Polar',
				path: 'charts/polar',
				icon: 'TrackChanges',
			},
			chartsRadialBar: {
				id: 'chartsRadialBar',
				text: 'Radial Bar',
				path: 'charts/radial-bar',
				icon: 'DonutLarge',
			},
			chartsBubble: {
				id: 'chartsBubble',
				text: 'Bubble',
				path: 'charts/bubble',
				icon: 'BubbleChart',
			},
			chartsScatter: {
				id: 'chartsScatter',
				text: 'Scatter',
				path: 'charts/scatter',
				icon: 'ScatterPlot',
			},
			chartsHeatMap: {
				id: 'chartsHeatMap',
				text: 'Heat Map',
				path: 'charts/heat-map',
				icon: 'GridOn',
			},
			chartsTreeMap: {
				id: 'chartsTreeMap',
				text: 'Tree Map',
				path: 'charts/tree-map',
				icon: 'AccountTree',
			},
		},
	},
	notification: {
		id: 'notification',
		text: 'Notification',
		path: 'notifications',
		icon: 'NotificationsNone',
	},
	hooks: {
		id: 'hooks',
		text: 'Hooks',
		path: 'hooks',
		icon: 'Anchor',
	},
};

export const productsExampleMenu = {
	companyA: { id: 'companyA', text: 'Company A', path: 'grid-pages/products', subMenu: null },
	companyB: { id: 'companyB', text: 'Company B', path: '/', subMenu: null },
	companyC: { id: 'companyC', text: 'Company C', path: '/', subMenu: null },
	companyD: { id: 'companyD', text: 'Company D', path: '/', subMenu: null },
};

export const evoHuntPages = {
	evohunt: {
		id: 'evohunt',
		text: 'EvoHunt',
		path: 'evohunt',
		icon: null,
		subMenu: {
			login: {
				id: 'login',
				text: 'Login',
				path: 'Login',
				icon: null,
			},
			candidatoIdeatoreSignup: {
				id: 'candidatoIdeatore',
				text: 'Signup',
				path: 'Signupci',
				icon: null,
			},
			aziendaStartupSignup: {
				id: 'aziendaStartup',
				text: 'Signup',
				path: 'Signupas',
				icon: null,
			},
			userProfile: {
				id: 'userProfileCandidato',
				text: 'User Profile',
				path: 'userProfileCandidato',
				icon: null,
			},
			aziendaProfileHome: {
				id: 'aziendaProfileHome',
				text: 'Azienda Profile',
				path: 'aziendaProfileHome',
				icon: null,
			},
			advisorPage: {
				id: 'advisorPage',
				text: 'Advisor Page',
				path: 'advisorPage',
				icon: null,
			},
			advisorDetail: {
				id: 'advisorDetail',
				text: 'Advisor Detail',
				path: 'advisorDetail',
				icon: null,
			},
			dettaglioCandidatoInRicerca: {
				id: 'dettaglioCandidatoInRicerca',
				text: 'Dettaglio Candidato',
				path: 'dettaglioCandidatoInRicerca',
				icon: null,
			},
			userDetail: {
				id: 'userDetail',
				text: 'Dettaglio Candidato',
				path: 'userDetail',
				icon: null,
			},
			userStatisticsSkills: {
				id: 'userStatisticsSkills',
				text: 'Statistiche individuali',
				path: 'userStatisticsSkills',
				icon: null,
			},
			userAssessmentSkills: {
				id: 'userAssessmentSkills',
				text: 'Valutazione competenze',
				path: 'userAssessmentSkills',
				icon: null,
			},
			descrizioneAzienda: {
				id: 'descrizioneAzienda',
				text: 'Descrizione Azienda',
				path: 'descrizioneAzienda',
				icon: null,
			},
			descrizioneAziendaDetail: {
				id: 'descrizioneAziendaDetail',
				text: 'Descrizione Azienda Detail',
				path: 'descrizioneAziendaDetail',
				icon: null,
			},
			linkAziendali: {
				id: 'linkAziendali',
				text: 'Link Aziendali',
				path: 'linkAziendali',
				icon: null,
			},
			datiReferente: {
				id: 'datiReferente',
				Text: 'Dati Referente',
				path: 'datiReferente',
				icon: null,
			},
			CuriositaPage: {
				id: 'CuriositaPage',
				text: 'Curiosità',
				path: 'CuriositaPage',
				icon: null,
			},
			FaqPage: {
				id: 'faqPage',
				text: 'F.A.Q.',
				path: 'faqPage',
				icon: null,
			},
			scelte: {
				id: 'scelte',
				text: 'Scelte',
				path: 'scelte',
				icon: null,
			},
			studies: {
				id: 'studies',
				text: 'Studies',
				path: 'studies',
				icon: null,
			},
			workingExperiences: {
				id: 'workingExperiences',
				text: 'Working Experiences',
				path: 'workingExperiences',
				icon: null,
			},
			languages: {
				id: 'languages',
				text: 'Languages',
				path: 'languages',
				icon: null,
			},
			volounteers: {
				id: 'volounteers',
				text: 'Volounteers',
				path: 'volounteers',
				icon: null,
			},
			payment: {
				id: 'payment',
				text: 'Payment',
				path: 'payment',
				icon: null,
			},
			widget: {
				id: 'widget',
				text: 'Widget',
				path: 'widget',
				icon: null,
			},
			statistics: {
				id: 'statistics',
				text: 'Statistics',
				path: 'statistics',
				icon: null,
			},
			competenze: {
				id: 'competenze',
				text: 'Competenze',
				path: 'competenze',
				icon: null,
			},
			certifications: {
				id: 'certifications',
				text: 'Certifications',
				path: 'certifications',
				icon: null,
			},
			courses: {
				id: 'courses',
				text: 'Courses',
				path: 'courses',
				icon: null,
			},
			jobsLookingFor: {
				id: 'jobsLookingFor',
				text: 'Jobs Looking For',
				path: 'jobsLookingFor',
				icon: null,
			},
			viewCompanyOffer: {
				id: 'viewCompanyOffer',
				text: 'View Company Offer',
				path: 'viewCompanyOffer',
				icon: null,
			},
			viewIdeatorOffer: {
				id: 'viewIdeatorOffer',
				text: 'View Ideator Offer',
				path: 'viewIdeatorOffer',
				icon: null,
			},
			viewStartupOffer: {
				id: 'viewStartupOffer',
				text: 'View Startup Offer',
				path: 'viewStartupOffer',
				icon: null,
			},
			statsArea: {
				id: 'statsArea',
				text: 'Stats Area',
				path: 'statsArea',
				icon: null,
			},
			starteamArea: {
				id: 'starteamArea',
				text: 'Starteam Area',
				path: 'starteamArea',
				icon: null,
			},
			creativaArea: {
				id: 'creativaArea',
				text: 'Creativa Area',
				path: 'creativaArea',
				icon: null,
			},
			workTrainingArea: {
				id: 'workTrainingArea',
				text: 'Work Training Area',
				path: 'workTrainingArea',
				icon: null,
			},
			cVAndIDArea: {
				id: 'cVAndIDArea',
				text: 'CV And ID Area',
				path: 'cVAndIDArea',
				icon: null,
			},
			coursesWithDetail: {
				id: 'coursesWithDetail',
				text: 'Courses With Detail',
				path: 'coursesWithDetail',
				icon: null,
			},
			courseDetail: {
				id: 'courseDetail',
				text: 'Course Detail',
				path: 'courseDetail',
				icon: null,
			},
			ideas: {
				id: 'ideas',
				text: 'Ideas',
				path: 'ideas',
				icon: null,
			},
			ideaDetail: {
				id: 'ideaDetail',
				text: 'Idea Detail',
				path: 'ideaDetail',
				icon: null,
			},
			motivations: {
				id: 'motivations',
				text: 'Motivations',
				path: 'motivations',
				icon: null,
			},
			insertUpdate: {
				id: 'insertUpdate',
				text: 'Insert Update',
				path: 'insertUpdate',
				icon: null,
			},
			testForm: {
				id: 'testForm',
				text: 'Test Form',
				path: 'testForm',
				icon: null,
			},
			savedTest: {
				id: 'savedTest',
				text: 'Saved Test',
				path: 'savedTest',
				icon: null,
			},
			patenti: {
				id: 'patenti',
				text: 'Patenti',
				path: 'patenti',
				icon: null,
			},
			setSteps: {
				id: 'setSteps',
				text: 'Set Steps',
				path: 'setSteps',
				icon: null,
			},
			ricerca: {
				id: 'ricerca',
				text: 'Ricerca',
				path: 'ricerca',
				icon: null,
				subMenu: {
					ricercaConArchetipo: {
						id: 'ricercaConArchetipo',
						text: 'Ricerca Con Archetipo',
						path: 'ricercaConArchetipo',
						icon: null,
					},
					ricercaPersonalizzata: {
						id: 'ricercaPersonalizzata',
						text: 'Ricerca Personalizzata',
						path: 'ricercaPersonalizzata',
						icon: null,
					},
					addStudyPage: {
						id: 'addStudyPage',
						text: 'AddStudyPage',
						path: 'addStudyPage',
						icon: null,
					},
					addExperiencePage: {
						id: 'addExperiencePage',
						text: 'Aggiungi Esperienza',
						path: 'addExperiencePage',
						icon: null,
					},
					addCompetencePage: {
						id: 'addCompetencePage',
						text: 'Aggiungi Competenza',
						path: 'addCompetencePage',
						icon: null,
					},
					addLanguagePage: {
						id: 'addLanguagePage',
						text: 'Aggiungi Lingua',
						path: 'addLanguagePage',
						icon: null,
					},
					addSoftSkillPage: {
						id: 'addSoftSkillPage',
						text: 'Aggiungi Soft Skill',
						path: 'addSoftSkillPage',
						icon: null,
					},
					addCoursePage: {
						id: 'addCoursePage',
						text: 'Aggiungi Corso',
						path: 'addCoursePage',
						icon: null,
					},
					addCertificationPage: {
						id: 'addCertificationPage',
						text: 'Aggiungi Certificazione',
						path: 'addCertificationPage',
						icon: null,
					},
				},
			},
			hobbySports: {
				id: 'hobbySports',
				text: 'HobbySports',
				path: 'hobbySports',
				icon: null,
				subMenu: {
					hobbies: {
						id: 'hobbies',
						text: 'Hobbies',
						path: 'hobbies',
						icon: null,
					},
					sports: {
						id: 'sports',
						text: 'Sports',
						path: 'sports',
						icon: null,
					},
				},
			},
			hobby: {
				id: 'hobbies',
				text: 'Hobbies',
				path: 'hobbies',
				icon: null,
			},
			sport: {
				id: 'sports',
				text: 'Sports',
				path: 'sports',
				icon: null,
			},
			certificationsOpenBadge: {
				id: 'certificationsOpenBadge',
				text: 'CertificationsOpenBadge',
				path: 'certificationsOpenBadge',
				icon: null,
				subMenu: {
					certifications: {
						id: 'certifications',
						text: 'Certifications',
						path: 'certifications',
						icon: null,
					},
					openBadges: {
						id: 'openBadges',
						text: 'OpenBadges',
						path: 'openBadges',
						icon: null,
					},
				},
			},
			descrizioneCandidatoTabbed: {
				id: 'descrizioneCandidatoTabbed',
				text: 'Descrizione Candidato Tabbed',
				path: 'descrizioneCandidatoTabbed',
				icon: null,
			},
			descriptionProfileCandidatoDetail: {
				id: 'descriptionProfileCandidatoDetail',
				text: 'Description User Profile Detail',
				path: 'descriptionProfileCandidatoDetail',
				icon: null,
			},
			descriptionProfileCandidato: {
				id: 'descriptionProfileCandidato',
				text: 'Description User Profile',
				path: 'descriptionProfileCandidato',
				icon: null,
			},
			homeCandidato: {
				id: 'homeCandidato',
				text: 'Home Candidato',
				path: 'homeCandidato',
				icon: null,
				subMenu: {
					applicationsPage: {
						id: 'applicationsPage',
						text: 'Applications Page',
						path: 'applicationsPage',
						icon: null,
					},
				},
			},
			homeAzienda: {
				id: 'homeAzienda',
				text: 'Home Azienda',
				path: 'homeAzienda',
				icon: null,
			},
			homeIdeatore: {
				id: 'homeIdeatore',
				text: 'Home Ideatore',
				path: 'homeIdeatore',
				icon: null,
			},
			homeStartup: {
				id: 'homeStartup',
				text: 'Home Startup',
				path: 'homeStartup',
				icon: null,
			},
			userProfileStartup: {
				id: 'userProfileStartup',
				text: 'User Profile Startup',
				path: 'userProfileStartup',
				icon: null,
			},
			candidatureTestVideoEducational: {
				id: 'candidatureTestVideoEducational',
				text: 'Candidature TestVideo Educational',
				path: 'candidatureTestVideoEducational',
				icon: null,
				subMenu: {
					educational: {
						id: 'educational',
						text: 'educational',
						path: 'educational',
						icon: null,
					},
					testVideo: {
						id: 'testVideo',
						text: 'Test Video',
						path: 'testVideo',
						icon: null,
						subMenu: {
							videoArea: {
								id: 'videoArea',
								text: 'Video Area',
								path: 'videoArea',
								icon: null,
							},
							testArea: {
								id: 'testArea',
								text: 'Test Area',
								path: 'testArea',
								icon: null,
							},
							testResults: {
								id: 'testResults',
								text: 'Test Results',
								path: 'testResults',
								icon: null,
							},
						},
					},
				},
			},
			messaggi: {
				id: 'messaggi',
				text: 'Messaggi',
				path: 'messaggi',
				icon: null,
			},
			messaggio: {
				id: 'messaggio',
				text: 'Messaggio',
				path: 'messaggio',
				icon: null,
			},
			chatSupporto: {
				id: 'chatSupporto',
				text: 'Chat Supporto',
				path: 'chatSupporto',
				icon: null,
			},
			softSkill: {
				id: 'softSkill',
				text: 'Soft Skill',
				path: 'softSkill',
				icon: null,
			},
			ricercheArchiviate: {
				id: 'ricercheArchiviate',
				text: 'Ricerche Archiviate',
				path: 'ricercheArchiviate',
				icon: null,
			},
			ricercheTerminate: {
				id: 'ricercheTerminate',
				text: 'Ricerche Terminate',
				path: 'ricercheTerminate',
				icon: null,
			},
			createTeam: {
				id: 'createTeam',
				text: 'Create Team',
				path: 'createTeam',
				icon: null,
			},
			planner: {
				id: 'planner',
				text: 'Planner',
				path: 'planner',
				icon: null,
			},
			compareCandidates: {
				id: 'compareCandidates',
				text: 'Compare Candidates',
				path: 'compareCandidates',
				icon: null,
			},
			queryCandidateList: {
				id: 'queryCandidateList',
				text: 'Query Candidate List',
				path: 'queryCandidateList',
				icon: null,
			},
			queryDetail: {
				id: 'queryDetail',
				text: 'Query Detail',
				path: 'queryDetail',
				icon: null,
			},
			richiediVideoAzienda: {
				id: 'requestVideo',
				text: 'Richiedi Video Azienda',
				path: 'requestVideo',
				icon: null,
			},
			richiediTestAzienda: {
				id: 'requestTest',
				text: 'Richiedi Test Azienda',
				path: 'requestTest',
				icon: null,
			},
			settings: {
				id: 'settings',
				text: 'Impostazioni',
				path: 'settings',
				icon: null,
			},
			changePassword: {
				id: 'changePassword',
				text: 'Cambio password',
				path: 'change-password',
				icon: null,
			},
			savedTests: {
				id: 'savedTests',
				text: 'Saved Tests',
				path: 'savedTests',
				icon: null,
			},
			iMieiTeam: {
				id: 'iMieiTeam',
				text: 'I Miei Team',
				path: 'iMieiTeam',
				icon: null,
			},
			notifiche: {
				id: 'notifiche',
				text: 'Notifiche',
				path: 'notifiche',
				icon: null,
			},
			detailOfferTabbed: {
				id: 'detailOfferTabbed',
				text: 'Dettaglio Offerta',
				path: 'detailOfferTabbed',
				icon: null,
			},
			queryTestVideo: {
				id: 'queryTestVideo',
				text: 'Test & Video',
				path: 'queryTestVideo',
				icon: null,
			},
			queryTestVideoTabbed: {
				id: 'queryTestVideoTabbed',
				text: 'Test & Video',
				path: 'queryTestVideoTabbed',
				icon: null,
			},
			queryTestDetail: {
				id: 'queryTestDetail',
				text: 'Test',
				path: 'queryTestDetail',
				icon: null,
			},
			advisor: {
				id: 'advisor',
				text: 'Advisor',
				path: 'advisor',
				icon: null,
			},
			tipsTabbed: {
				id: 'tipsTabbed',
				text: 'Tips',
				path: 'tipsTabbed',
				icon: null,
			},
			testVideoTabbed: {
				id: 'testVideoTabbed',
				text: 'TestVideo',
				path: 'testVideoTabbed',
				icon: null,
			},
			companyTestVideo: {
				id: 'companyTestVideo',
				text: 'companyTestVideo',
				path: 'companyTestVideo',
				icon: null,
			},
			viewTestPage: {
				id: 'viewTestPage',
				text: 'ViewTest',
				path: 'viewTestPage',
				icon: null,
			},
			viewCompletedTestPage: {
				id: 'viewCompletedTestPage',
				text: 'ViewCompletedTest',
				path: 'viewCompletedTestPage',
				icon: null,
			},
			companyTeamPage: {
				id: 'companyTeamPage',
				text: 'CompanyTeam',
				path: 'companyTeamPage',
				icon: null,
			},
			companyTeamDetailPage: {
				id: 'companyTeamDetailPage',
				text: 'CompanyTeamDetail',
				path: 'companyTeamDetailPage',
				icon: null,
			},
			companyTeamSkillDetailPage: {
				id: 'companyTeamSkillDetailPage',
				text: 'CompanyTeamSkillDetail',
				path: 'companyTeamSkillDetailPage',
				icon: null,
			},
			companyTeamPersonalisedSearch: {
				id: 'companyTeamPersonalisedSearch',
				text: 'CompanyTeamPersonalisedSearch',
				path: 'companyTeamPersonalisedSearch',
				icon: null,
			},
			companyTeamSearchDetail: {
				id: 'companyTeamSearchDetail',
				text: 'CompanyTeamSearchDetail',
				path: 'companyTeamSearchDetail',
				icon: null,
			},
			companyTeamSearchDetailParametersPage: {
				id: 'companyTeamSearchDetailParametersPage',
				text: 'CompanyTeamSearchDetailParametersPage',
				path: 'companyTeamSearchDetailParametersPage',
				icon: null,
			},
			companyTeamStatisticPage: {
				id: 'companyTeamStatisticPage',
				text: 'CompanyTeamStatisticPage',
				path: 'companyTeamStatisticPage',
				icon: null,
			},
			evaluateCompetencesPage: {
				id: 'evaluateCompetencesPage',
				text: 'EvaluateCompetencesPage',
				path: 'evaluateCompetencesPage',
				icon: null,
			},
			teamComponentCompetencePage: {
				id: 'teamComponentCompetencePage',
				text: 'TeamComponentCompetencePage',
				path: 'teamComponentCompetencePage',
				icon: null,
			},
			teamComponentStatisticPage: {
				id: 'teamComponentStatisticPage',
				text: 'TeamComponentStatisticPage',
				path: 'teamComponentStatisticPage',
				icon: null,
			},
			teamSearchResultsPage: {
				id: 'teamSearchResultsPage',
				text: 'TeamSearchResultsPage',
				path: 'teamSearchResultsPage',
				icon: null,
			},
			editCompanySearchResults: {
				id: 'editCompanySearchResults',
				text: 'EditCompanySearchResults',
				path: 'editCompanySearchResults',
				icon: null,
			},
			companyMap: {
				id: 'companyMap',
				text: 'CompanyMap',
				path: 'companyMap',
				icon: null,
			},
			companyMapDistributionDetail: {
				id: 'companyMapDistributionDetail',
				text: 'CompanyMapDistributionDetail',
				path: 'companyMapDistributionDetail',
				icon: null,
			},
			companyMapEmployeesPage: {
				id: 'companyMapEmployeesPage',
				text: 'CompanyMapEmployeesPage',
				path: 'companyMapEmployeesPage',
				icon: null,
			},
			userDetailPage: {
				id: 'userDetailPage',
				text: 'userDetailPage',
				path: 'userDetailPage',
				icon: null,
			},
			startupStatisticsPage: {
				id: 'startupStatisticsPage',
				text: 'STATISTICHE',
				path: 'startupStatisticsPage',
				icon: null,
			},
			ideatoreStatisticsPage: {
				id: 'ideatoreStatisticsPage',
				text: 'STATISTICHE',
				path: 'ideatoreStatisticsPage',
				icon: null,
			},
			datiReferenteDetail: {
				id: 'datiReferenteDetail',
				Text: 'Dati Referente',
				path: 'datiReferenteDetail',
				icon: null,
			},
			linkAziendaliDetail: {
				id: 'linkAziendaliDetail',
				Text: 'Link Aziendali',
				path: 'linkAziendaliDetail',
				icon: null,
			},
			companySectors: {
				id: 'companySectors',
				Text: 'Settori Aziendali',
				path: 'companySectors',
				icon: null,
			},
			companyAwards: {
				id: 'companyAwards',
				Text: 'Premi Aziendali',
				path: 'companyAwards',
				icon: null,
			},
			companyImages: {
				id: 'companyImages',
				Text: 'Immagini Aziendali',
				path: 'companyImages',
				icon: null,
			},
			companyLocations: {
				id: 'companyLocations',
				Text: 'Indirizzi Sedi',
				path: 'companyLocations',
				icon: null,
			},
		},
	},
};

export const navButton = {
	candidato: [
		{
			id: 'homeCandidato',
			text: 'HOME',
			path: '/',
			icon: 'Home',
		},
		{
			id: 'messaggi',
			text: 'MESSAGGI',
			path: 'messaggi',
			icon: 'Mail',
		},
		{
			id: 'notifications',
			text: 'NOTIFICHE',
			path: 'notifiche',
			icon: 'Notifications',
		},
		{
			id: 'tips',
			text: 'TIPS',
			path: 'tipsTabbed',
			icon: 'Cases',
		},
	],
	company: [
		{
			id: 'homeAzienda',
			text: 'HOME',
			path: '/',
			icon: 'Home',
		},
		{
			id: 'messaggi',
			text: 'MESSAGGI',
			path: 'messaggi',
			icon: 'Mail',
		},
		{
			id: 'notifications',
			text: 'NOTIFICHE',
			path: 'notifiche',
			icon: 'Notifications',
		},
		{
			id: 'tips',
			text: 'TIPS',
			path: 'tipsTabbed',
			icon: 'Cases',
		},
	],
	startup: [
		{
			id: 'homeStartup',
			text: 'HOME',
			path: '/',
			icon: 'Home',
		},
		{
			id: 'messaggi',
			text: 'MESSAGGI',
			path: 'messaggi',
			icon: 'Mail',
		},
		{
			id: 'notifications',
			text: 'NOTIFICHE',
			path: 'notifiche',
			icon: 'Notifications',
		},
		{
			id: 'tips',
			text: 'TIPS',
			path: 'tipsTabbed',
			icon: 'Cases',
		},
	],
	ideatore: [
		{
			id: 'homeIdeatore',
			text: 'HOME',
			path: '/',
			icon: 'Home',
		},
		{
			id: 'messaggi',
			text: 'MESSAGGI',
			path: 'messaggi',
			icon: 'Mail',
		},

		{
			id: 'notifications',
			text: 'NOTIFICHE',
			path: 'notifiche',
			icon: 'Notifications',
		},
		{
			id: 'tips',
			text: 'TIPS',
			path: 'tipsTabbed',
			icon: 'Cases',
		},
	],
};

export const burgerButtonsUser = [
	{
		id: 'homeCandidato',
		text: 'HOME',
		path: 'homeCandidato',
		icon: null,
	},
	{
		id: 'userProfileCandidato',
		text: 'PROFILO',
		path: 'userProfileCandidato',
		icon: null,
	},
	{
		id: 'impostazioni',
		text: 'IMPOSTAZIONI',
		path: 'settings',
		icon: null,
	},
	{
		id: 'changePassword',
		text: 'CAMBIO PASSWORD',
		path: 'change-password',
		icon: null,
	},
];

export const burgerButtonsCompany = [
	{
		id: 'homeAzienda',
		text: 'HOME',
		path: 'homeAzienda',
		icon: null,
	},
	{
		id: 'aziendaProfileHome',
		text: 'PROFILO',
		path: 'aziendaProfileHome',
		icon: null,
	},
	{
		id: 'programmazioneAttivita',
		text: 'PROGRAMMAZIONE ATTIVITA',
		path: 'planner',
		icon: null,
	},
	/*{
		id: 'chatSupporto',
		text: 'CHAT DI SUPPORTO',
		path: 'chatSupporto',
		icon: null,
	},*/
	{
		id: 'nuovaRicerca',
		text: 'NUOVA RICERCA',
		path: 'ricerca',
		icon: null,
	},
	{
		id: 'companyMap',
		text: 'MAPPA AZIENDALE',
		path: 'companyMap',
		icon: null,
	},
	{
		id: 'statistics',
		text: 'STATISTICHE',
		path: 'statistics',
		icon: null,
	},
	{
		id: 'impostazioni',
		text: 'IMPOSTAZIONI',
		path: 'settings',
		icon: null,
	},
	{
		id: 'changePassword',
		text: 'CAMBIO PASSWORD',
		path: 'change-password',
		icon: null,
	},
];

export const burgerButtonsStartup = [
	{
		id: 'homeStartup',
		text: 'HOME',
		path: 'homeStartup',
		icon: null,
	},
	{
		id: 'userProfileStartup',
		text: 'PROFILO',
		path: 'userProfileStartup',
		icon: null,
	},
	/*{
		id: 'chatSupporto',
		text: 'CHAT DI SUPPORTO',
		path: 'chatSupporto',
		icon: null,
	},*/
	{
		id: 'nuovaRicerca',
		text: 'NUOVA RICERCA',
		path: 'ricerca',
		icon: null,
	},
	{
		id: 'startupStatisticsPage',
		text: 'STATISTICHE',
		path: 'startupStatisticsPage',
		icon: null,
	},
	{
		id: 'impostazioni',
		text: 'IMPOSTAZIONI',
		path: 'settings',
		icon: null,
	},
	{
		id: 'changePassword',
		text: 'CAMBIO PASSWORD',
		path: 'change-password',
		icon: null,
	},
];

export const burgerButtonsIdeator = [
	{
		id: 'homeIdeatore',
		text: 'HOME',
		path: 'homeIdeatore',
		icon: null,
	},
	{
		id: 'userProfileIdeatore',
		text: 'PROFILO',
		path: 'userProfileIdeatore',
		icon: null,
	},
	{
		id: 'sviluppo',
		text: 'SVILUPPO',
		path: 'sviluppo',
		icon: null,
	},
	{
		id: 'nuovaRicerca',
		text: 'NUOVA RICERCA',
		path: 'ricerca',
		icon: null,
	},
	/*{
		id: 'chatSupporto',
		text: 'CHAT DI SUPPORTO',
		path: 'chatSupporto',
		icon: null,
	},*/
	{
		id: 'ideatoreStatisticsPage',
		text: 'STATISTICHE',
		path: 'ideatoreStatisticsPage',
		icon: null,
	},
	{
		id: 'impostazioni',
		text: 'IMPOSTAZIONI',
		path: 'settings',
		icon: null,
	},
	{
		id: 'changePassword',
		text: 'CAMBIO PASSWORD',
		path: 'change-password',
		icon: null,
	},
];

export function burgerButtons(role: string) {
	switch (role) {
		case 'USER':
			return burgerButtonsUser;
		case 'IDEATOR':
			return burgerButtonsIdeator;
		case 'COMPANY':
			return burgerButtonsCompany;
		case 'STARTUP':
		default:
			return burgerButtonsStartup;
	}
}
