import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import EvoHuntMobileHeader from '../../../components/evohunt/common/ui/header/EvoHuntMobileHeader';
import EvoHuntDesktopHeader from '../../../components/evohunt/common/ui/header/EvoHuntDesktopHeader';
import useIsMobile from '../../../hooks/useIsMobile';

const DashboardHeader = () => {
	const [isMobile, setIsMobile] = React.useState(false);

	useEffect(() => useIsMobile(setIsMobile), []);

	const navigate = useNavigate();
	const location = useLocation();

	const handleBack = (): void => {
		const doesAnyHistoryEntryExist = location.key !== 'default';
		if (doesAnyHistoryEntryExist) {
			navigate(-1);
		} else {
			navigate('/', { replace: true });
		}
	};

	return (
		<>
			{isMobile ? (
				<EvoHuntMobileHeader handleMenuOpen={(): void => {}} handleBack={handleBack} />
			) : (
				<EvoHuntDesktopHeader handleBack={handleBack} />
			)}
		</>
	);
};

export default DashboardHeader;
