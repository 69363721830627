import { createContext, ReactNode, useEffect, useMemo, useState } from 'react';
import { ROLE } from '../constants/constants';

interface UserRoleContextProps {
	role: string | undefined;
	setRole: (role: string) => void;
}

const UserRoleContext = createContext<UserRoleContextProps>({
    role: ROLE.USER,
    setRole: (role: string) => {}
});

const UserRoleProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const [role, setRole] = useState(() => {
        const savedRole = localStorage.getItem('authRole');
        return savedRole ? savedRole : undefined;
    });

    useEffect(() => {
        if (role) {
            localStorage.setItem('authRole', role);
        } else {
            localStorage.removeItem('authRole');
        }
    }, [role]);

    const value = useMemo(() => ({ role, setRole }), [role]);
    
    return (
        <UserRoleContext.Provider value={value}>
            {children}
        </UserRoleContext.Provider>
    );
}

export { UserRoleContext, UserRoleProvider };
export type { UserRoleContextProps };

