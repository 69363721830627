import { AUTH_ID, AUTH_ROLE, COMPANY_ID, COMPANY_UUID } from '../../constants/auth.constants';
import { AVATAR, LANGUAGE, LANGUAGE_ID } from '../../constants/localstorage.constants';

export function getLoggedCompanyId(): number {
	return +localStorage.getItem(COMPANY_ID)!;
}

export function getLoggedCompanyUuid(): string {
	return localStorage.getItem(COMPANY_UUID)!;
}

export function getLoggedUserId(): number {
	return +localStorage.getItem(AUTH_ID)!;
}

export function getLoggedUserRole(): string {
	return localStorage.getItem(AUTH_ROLE) ?? '';
}

export function getLoggedAvatar(): string {
	return localStorage.getItem(AVATAR) ?? '';
}

export function getLanguage(): string {
	return localStorage.getItem(LANGUAGE) ?? 'it';
}

export function getLanguageId(): number {
	return localStorage.getItem(LANGUAGE_ID) ? +localStorage.getItem(LANGUAGE_ID)! : 1;
}
