import { ROLE } from '../constants/constants';
import IconCandidate from '../assets/ui-icons/landing/candidato.svg';
import IconCandidateWhite from '../assets/ui-icons/landing/candidato-white.svg';
import IconCompany from '../assets/ui-icons/landing/azienda.svg';
import IconCompanyWhite from '../assets/ui-icons/landing/azienda-white.svg';
import IconIdeator from '../assets/ui-icons/landing/ideatore.svg';
import IconIdeatorWhite from '../assets/ui-icons/landing/ideatore-white.svg';
import IconStartUp from '../assets/ui-icons/landing/start-up.svg';
import IconStartUpWhite from '../assets/ui-icons/landing/start-up-white.svg';

export function getProfileDefaultIcon(role: string, color: boolean = true): string {
	switch (role) {
		case ROLE.COMPANY:
			return color ? IconCompany : IconCompanyWhite;
		case ROLE.IDEATOR:
			return color ? IconIdeator : IconIdeatorWhite;
		case ROLE.STARTUP:
			return color ? IconStartUp : IconStartUpWhite;
		case ROLE.USER:
		default:
			return color ? IconCandidate : IconCandidateWhite;
	}
}