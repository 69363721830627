import React from 'react';
import { createRoot } from 'react-dom/client';
import { HashRouter as Router } from 'react-router-dom';
import './styles/styles.scss';
import App from './App/App';
import './Translation/i18n';
import reportWebVitals from './reportWebVitals';
import { AuthContextProvider } from './contexts/authContext';
import './i18n';
import { UserRoleProvider } from './contexts/userRoleContext';
import { Provider } from 'react-redux';
import { persistor, store } from './redux/store';
import { PersistGate } from 'redux-persist/integration/react';

const children = (
	<AuthContextProvider>
		<UserRoleProvider>
			<Provider store={store}>
				<PersistGate loading={null} persistor={persistor}>
					<Router>
						<App />
					</Router>
				</PersistGate>
			</Provider>
		</UserRoleProvider>
	</AuthContextProvider>
);

const container = document.getElementById('root');

createRoot(container as Element).render(children);

reportWebVitals();
