import { AppBar, IconButton, Toolbar } from '@mui/material';
import React from 'react';
import EvoBackIcon from '../../../../../assets/ui-icons/freccia.svg';
import { dashboardPagesMenu } from '../../../../../menu';
import { Link, useLocation } from 'react-router-dom';
import ReactiveDrawerMenu from '../menu/ReactiveDrawerMenu';
import evohuntLogo from '../../../../../assets/evohunt_logo.png';
import { getLoggedUserId } from '../../../../../common/function/utilities';

interface IEvohuntMobileHeaderProps {
	handleMenuOpen: () => void;
	handleBack: () => void;
}

const EvoHuntMobileHeader: React.FC<IEvohuntMobileHeaderProps> = ({
	handleMenuOpen,
	handleBack,
}) => {
	const [currentPage, setCurrentPage] = React.useState<string>('');

	const location = useLocation();

	const mainPages: boolean =
		location.pathname === '/' ||
		location.pathname === '/messaggi' ||
		location.pathname === '/notifiche' ||
		location.pathname === '/tipsTabbed' ||
		location.pathname === '/aziendaProfileHome' ||
		location.pathname === '/userProfileCandidato' ||
		location.pathname === '/userProfileStartup' ||
		location.pathname === '/preview' ||
		location.pathname === '/candidate-preview' ||
		location.pathname === '/idea-preview' ||
		location.pathname === '/homeCandidato' ||
		location.pathname === '/homeAzienda' ||
		location.pathname === '/homeIdeatore' ||
		location.pathname === '/homeStartup';

	React.useEffect(() => {
		const path = window.location.pathname;
		const splittedPath = path.split('/');
		const page = splittedPath[splittedPath.length - 1];

		setCurrentPage(page);
	}, []);

	return (
		<>
			{location.pathname !== '/' && (
				<div className={`appBarContainer`}>
					<AppBar position='fixed' className='header_appbar'>
						<Toolbar className='header_toolbar'>
							<div>
								{mainPages ? (
									''
								) : (
									<IconButton
										edge={false}
										className='backButton'
										color='inherit'
										aria-label='Back'
										onClick={handleBack}>
										<img src={EvoBackIcon} alt='EvoBackIcon' />
									</IconButton>
								)}
							</div>

							<Link
								to={`../${dashboardPagesMenu.evohunt.path}`}
								className='link_logo'>
								<img src={evohuntLogo} alt='evohunt_logo' />
							</Link>

							{getLoggedUserId() ? <ReactiveDrawerMenu iconSize='2rem' color='#007bfb' /> : <div style={{ width: "5rem" }}></div>}
						</Toolbar>
					</AppBar>
				</div>
			)}
		</>
	);
};

export default EvoHuntMobileHeader;
