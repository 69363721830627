import { ITranslation } from '../common/io/translation';

export function getTranslationByLanguage(input: ITranslation[], language: string): ITranslation {
	return _translate(input, language);
}

export function translateName(input: ITranslation[], language: string): string {
    let element: ITranslation = _translate(input, language);
    return element?.name ?? '';
}

export function translateTitle(input: ITranslation[], language: string): string {
    let element: ITranslation = _translate(input, language);
    return element?.title ?? '';
}

export function translateValue(input: ITranslation[], language: string): string {
    let element: ITranslation = _translate(input, language);
    return element?.value ?? '';
}

export function translateDescription(input: ITranslation[], language: string): string {
    let element: ITranslation = _translate(input, language);
    return element?.description ?? '';
}

function _translate(input: ITranslation[], language: string): ITranslation {
    let out: ITranslation[] = input.filter((e: ITranslation): boolean => e.lang?.code === language);
    if (out.length > 0) {
        return out[0];
    }
    return input[0];
}
