export const CLIENT_ID: string = "Client-ID";
export const LANGUAGE: string = 'language';
export const CONTENT_TYPE: string = 'Content-Type';
export const CONTENT_TYPE_APPLICATION_JSON: string = 'application/json';
export const CONTENT_TYPE_MULTIPART_FORM_DATA: string = 'multipart/form-data';
export const CACHE_CONTROL = 'Cache-Control';
export const CACHE_CONTROL_VALUE = 'no-cache';
export const PRAGMA = 'Pragma';
export const PRAGMA_VALUE = 'no-cache';
export const EXPIRES = 'Expires'
export const EXPIRES_VALUE = '0'
export const X_REQUEST_ID = 'X-Request-ID';