import Pagination from '../common/io/pagination';
import { ISimpleItemProps } from '../components/evohunt/interfaces/ISimpleItemProps';

export const FONT_FAMILY: string = 'Poppins';

export const ELEMENTS_PER_PAGE: number = 100;
export const DEBOUNCE_MILLISECONDS: number = 500;
export const MIN_ELEMENT_SEARCH: number = 5;
export const MOBILE_WIDTH: number = 768;
export const RATING_PRECISION: number = .5
export const MIN_MONTH: number = 1;
export const MAX_MONTH: number = 12;
export const MIN_YEAR: number = 1900;
export const MIN_STEPS: number = 3;
export function MAX_YEAR(): number {
  return new Date().getFullYear();
}
export const MIN_RAL: number = 0;
export const MAX_RAL: number = 9999999;
export const MIN_NOTICE: number = 1;
export const MAX_NOTICE: number = 1000;
export const LEVEL_STEP: number = .5;
export const MIN_LEVEL: number = 1;
export const MAX_LEVEL: number = 5;
export const MIN_HOURS: number = 1;
export const MAX_HOURS: number = 168;
export const SHOW_MORE_LENGTH: number = 180;
export const SHOW_MORE_LENGTH_MOBILE: number = 50;
export const SURVEY_DELTA: number = 180;

export const COLOR_BLACK: string = '#000000';
export const COLOR_BLUE: string = '#0089FB';
export const COLOR_GRAY: string = '#CCCCCC';
export const COLOR_GREEN: string = '#0F9744';
export const COLOR_RED: string = 'red';
export const COLOR_WHITE: string = '#FFFFFF';

export const DOCUMENT_TYPE_ID: number = 1;
export const DOCUMENT_TYPE_CF: number = 2;
export const DOCUMENT_TYPE_DRIVING_LICENSE: number = 3;

export const DEFAULT_LANGUAGE: string = 'it';

export const pagination = (page: number): Pagination => {
  return {
    page: page,
    elementsPerPage: ELEMENTS_PER_PAGE
  };
}

export const BASE_URL_REGEX: RegExp = /^(http|https):\/\//;
export const URL_REGEX: RegExp = new RegExp(
    '^(https?:\\/\\/)?' + // validate protocol
        '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // validate domain name
        '((\\d{1,3}\\.){3}\\d{1,3}))' + // validate OR ip (v4) address
        '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // validate port and path
        '(\\?[;&a-z\\d%_.~+=-]*)?' + // validate query string
        '(\\#[-a-z\\d_]*)?$',
    'i'
);

export const NUMBER_REGEX: RegExp = /^[0-9\b]+$/;
export const PHONE_REGEX: RegExp = /^(\+|00)?\d{1,4}?\d{6,10}$/;
export const EMAIL_REGEX: RegExp = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
export const USERNAME_REGEX: RegExp = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;

export const PASSWORD_NUMBER_REGEX: RegExp = /\d/;
export const PASSWORD_UPPER_CASE_REGEX: RegExp = /[A-Z]/;
export const PASSWORD_LOWER_CASE_REGEX: RegExp = /[a-z]/;
export const PASSWORD_SPECIAL_CHAR_REGEX: RegExp = /[ !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/;
export const PASSWORD_MIN_LENGTH: number = 8;

export const ROUTE_FROM = {
  COMPANY: 'company',
  IDEA: 'idea',
  QUERY: 'query',
  USER: 'user',
  TEAM: 'team'
}

export const ROLE = {
  USER: 'USER',
  IDEATOR: 'IDEATOR',
  COMPANY: 'COMPANY',
  STARTUP: 'STARTUP'
}

export const USER_STATUS = {
  ACTIVE: 'ACTIVE'
}

export const GENDER = {
  ND: 'ND',
  M: 'M',
  F: 'F',
  O: 'O'
}

export const REVENUE_RANGE: ISimpleItemProps[] = [
  {id: "0 - 10000", title: "0 - 10.000"},
  {id: "10.000 - 50.000", title: "10.000 - 50.000"},
  {id: "50.000 - 100.000", title: "50.000 - 100.000"},
  {id: "100.000 - 1.000.000", title: "10.0000 - 1.000.000"},
  {id: "+1.000.000", title: "+1.000.000"},
];

export const EMPLOYEE_NUMBER: ISimpleItemProps[] = [
  {id: "0 - 10", title: "0 - 10"},
  {id: "10 - 25", title: "10 - 25"},
  {id: "25 - 50", title: "25 - 50"},
  {id: "50 - 100", title: "50 - 100"},
  {id: "+100", title: "+100"},
];
