import { lazy } from 'react';
import { RouteProps } from 'react-router-dom';
import { dashboardPagesMenu, evoHuntPages } from '../menu';

const LANDING = {
	EVOHUNT: lazy(() => import('../pages/landing-page/LandingPage')),
	COMPANY_PREVIEW: lazy(() => import('../pages/presentation/evohunt/CompanyPreview')),
	CANDIDATE_PREVIEW: lazy(() => import('../pages/presentation/evohunt/CandidatePreview')),
	IDEA_PREVIEW: lazy(() => import('../pages/presentation/evohunt/IdeaPreview')),
};

const EVOHUNTPAGES = {
	LOGIN: lazy(() => import('../pages/presentation/evohunt/LoginPage')),
	CANDIDATOIDEATORE: lazy(
		() => import('../pages/presentation/evohunt/RegisterUserIdeatorPage'),
	),
	AZIENDASTARTUP: lazy(() => import('../pages/presentation/evohunt/RegisterCompanyStartupPage')),
	USERPROFILE: lazy(
		() => import('../pages/presentation/evohunt/UserProfilePage'),
	),
	AZIENDAPROFILEHOME: lazy(() => import('../pages/presentation/evohunt/AziendaProfileHomePage')),
	DETTAGLIOCANDIDATOINRICERCA: lazy(
		() => import('../pages/presentation/evohunt/UserDetailPage'),
	),
	DESCRIZIONEAZIENDAPAGE: lazy(
		() => import('../pages/presentation/evohunt/DescrizioneAziendaPage'),
	),
	DESCRIZIONEAZIENDADETAIL: lazy(() => import('../pages/presentation/evohunt/DescrizioneAziendaDetail')),
	DATIREFERENTE: lazy(() => import('../pages/presentation/evohunt/DatiReferentePageNew')),
	CURIOSITAPAGE: lazy(() => import('../pages/presentation/evohunt/CuriositaPage')),
	FAQPAGE: lazy(() => import('../pages/presentation/evohunt/FaqPage')),
	LINKAZIENDALI: lazy(() => import('../pages/presentation/evohunt/LinkAziendaliPage')),
	SCELTE: lazy(() => import('../pages/presentation/evohunt/SceltePage')),
	STUDIES: lazy(() => import('../pages/presentation/evohunt/StudiesPage')),
	WORKINGEXPERIENCES: lazy(() => import('../pages/presentation/evohunt/WorkingExperiencesPage')),
	LANGUAGES: lazy(() => import('../pages/presentation/evohunt/LanguagesPage')),
	VOLOUNTEERS: lazy(() => import('../pages/presentation/evohunt/VolunteersPage')),
	COMPETENZE: lazy(() => import('../pages/presentation/evohunt/CompetenzePage')),
	CERTIFICATIONS: lazy(() => import('../pages/presentation/evohunt/CertificationsPage')),
	COURSES: lazy(() => import('../pages/presentation/evohunt/CoursesPage')),
	JOBSLOOKINGFOR: lazy(() => import('../pages/presentation/evohunt/JobsLookingForPage')),
	IDEAS: lazy(() => import('../pages/presentation/evohunt/IdeasPage')),
	TESTAREA: lazy(
		() =>
			import(
				'../pages/presentation/evohunt/candidaturetestvideoeducational/testvideo/TestAreaPage'
			),
	),
	VIDEOAREA: lazy(
		() =>
			import(
				'../pages/presentation/evohunt/candidaturetestvideoeducational/testvideo/VideoAreaPage'
			),
	),
	CVANDIDAREA: lazy(() => import('../pages/presentation/evohunt/CVAndIDAreaPage')),
	MOTIVATIONS: lazy(() => import('../pages/presentation/evohunt/MotivationsPage')),
	IDEADETAIL: lazy(() => import('../pages/presentation/evohunt/IdeaDetail')),
	INSERTUPDATE: lazy(() => import('../pages/presentation/evohunt/InsertUpdatePage')),
	TEST_FORM: lazy(() => import('../pages/presentation/evohunt/homeCompany/companyTestVideo/QueryTestTabbedPage')),
	SAVED_TEST: lazy(() => import('../pages/presentation/evohunt/homeCompany/companyTestVideo/CompanySavedTestPage')),
	PATENTI: lazy(() => import('../pages/presentation/evohunt/DriverLicensePages')),
	HOBBYSPORT: lazy(() => import('../pages/presentation/evohunt/HobbySportPage')),
	HOBBIES: lazy(() => import('../pages/presentation/evohunt/hobbysports/HobbiesPage')),
	SPORTS: lazy(() => import('../pages/presentation/evohunt/hobbysports/SportsPage')),
	CERTIFICATIONSOPENBADGES: lazy(
		() => import('../pages/presentation/evohunt/TestCertificationOpenBadgeTabbedPage'),
	),
	OPENBADGES: lazy(
		() => import('../pages/presentation/evohunt/OpenBadgesPage'),
	),
	VIEWCOMPANYOFFER: lazy(() => import('../pages/presentation/evohunt/ViewCompanyOfferPage')),
	VIEWSTARTUPOFFER: lazy(() => import('../pages/presentation/evohunt/ViewStartupOfferPage')),
	VIEWIDEATOROFFER: lazy(() => import('../pages/presentation/evohunt/ViewIdeatorOfferPage')),
	COURSESWITHDETAIL: lazy(() => import('../pages/presentation/evohunt/CoursesWithDetailPage')),
	COURSEDETAIL: lazy(() => import('../pages/presentation/evohunt/CourseDetailPage')),
	ADVISORPAGE: lazy(() => import('../pages/presentation/evohunt/TestAdvisorPage')),
	ADVISORDETAIL: lazy(() => import('../pages/presentation/evohunt/AdvisorDetailPage')),
	STATSAREA: lazy(() => import('../pages/presentation/evohunt/StatsAreaPage')),
	STARTEAMAREA: lazy(() => import('../pages/presentation/evohunt/StarteamAreaPage')),
	CREATIVAAREA: lazy(() => import('../pages/presentation/evohunt/CreativaAreaPage')),
	WORKTRAININGAREA: lazy(() => import('../pages/presentation/evohunt/WorkTrainingAreaPage')),
	SETSTEPS: lazy(() => import('../pages/presentation/evohunt/SetStepsPage')),
	RICERCA: lazy(() => import('../pages/presentation/evohunt/TestRicercaPageTabbed')),
	RICERCHEARCHIVIATE: lazy(
		() => import('../pages/presentation/evohunt/TestArchivioRicercheTabbedPage'),
	),
	RICERCHETERMINATE: lazy(() => import('../pages/presentation/evohunt/RicercheTerminate')),
	CREATETEAM: lazy(() => import('../pages/presentation/evohunt/CreateTeamPage')),
	SAVEDTESTS: lazy(() => import('../pages/presentation/evohunt/SavedTestsPage')),
	RICERCACONARCHETIPO: lazy(
		() => import('../pages/presentation/evohunt/ricerca/RicercaConArchetipoPage'),
	),
	RICERCAPERSONALIZZATA: lazy(
		() => import('../pages/presentation/evohunt/ricerca/RicercaPersonalizzataPage'),
	),
	DESCRIZIONECANDIDATOTABBED: lazy(
		() => import('../pages/presentation/evohunt/DescrizioneCandidatoTabbedPage'),
	),
	DESCRIPTIONPROFILECANDIDATODETAIL: lazy(
		() => import('../pages/presentation/evohunt/DescrizioneProfiloCandidatoDetail'),
	),
	DESCRIPTIONPROFILECANDIDATO: lazy(
		() => import('../pages/presentation/evohunt/DescrizioneProfiloCandidatoPage'),
	),
	USERPROFILESTARTUP: lazy(() => import('../pages/presentation/evohunt/UserProfileStartUpPage')),
	HOMECANDIDATO: lazy(() => import('../pages/presentation/evohunt/TestHomeApplicantTabbedPage')),
	HOMEAZIENDA: lazy(() => import('../pages/presentation/evohunt/TestHomeCompanyTabbedPage')),
	HOMEIDEATORE: lazy(() => import('../pages/presentation/evohunt/HomeIdeatorePage')),
	HOMESTARTUP: lazy(() => import('../pages/presentation/evohunt/HomeStartUpPage')),
	SOFTSKILL: lazy(() => import('../pages/presentation/evohunt/SoftSkillPage')),
	IMIEITEAM: lazy(() => import('../pages/presentation/evohunt/IMieiTeamPage')),
	NOTIFICHE: lazy(() => import('../pages/presentation/evohunt/NotificationPage')),
	TESTVIDEO: lazy(
		() => import(
			'../pages/presentation/evohunt/candidaturetestvideoeducational/TestVideoApplicantPage'
		),
	),
	EDUCATIONAL: lazy(() => import('../pages/presentation/evohunt/TestApplicantEducationalPage')),
	PAYMENT: lazy(() => import('../pages/presentation/evohunt/PaymentPage')),
	CANDIDATURETESTVIDEOEDUCATIONAL: lazy(
		() => import('../pages/presentation/evohunt/CandidatureTestVideoEducationalPage'),
	),
	WIDGET: lazy(() => import('../pages/presentation/evohunt/CompanyWidgetPage')),
	COMPANY_STATISTICS: lazy(() => import('../pages/presentation/evohunt/CompanyStatisticsPage')),
	COMPANY_PLANNER: lazy(() => import('../pages/presentation/evohunt/CompanyPlannerPage')),
	COMPARE_CANDIDATES: lazy(() => import('../pages/presentation/evohunt/CompareCandidatesPage')),
	QUERY_CANDIDATE_LIST: lazy(
		() => import('../pages/presentation/evohunt/QueryCandidateListPage'),
	),
	QUERY_DETAIL: lazy(() => import('../pages/presentation/evohunt/QueryDetailPage')),
	RICHIEDIVIDEO: lazy(() => import('../pages/presentation/evohunt/RichiediVideoAzienda')),
	RICHIEDITEST: lazy(() => import('../pages/presentation/evohunt/RichiediTestAzienda')),
	SETTINGS: lazy(() => import('../pages/presentation/evohunt/Settings')),
	CHANGE_PASSWORD: lazy(() => import('../pages/presentation/evohunt/ChangePassword')),
	DETAILOFFERTABBED: lazy(
		() => import('../pages/presentation/evohunt/TestDetailOfferTabbedPage'),
	),
	QUERY_TEST_VIDEO: lazy(
		() => import('../pages/presentation/evohunt/homeCompany/companyTestVideo/QueryCandidateListTestVideoPage'),
	),
	QUERY_TEST_VIDEO_TABBED: lazy(
		() => import('../pages/presentation/evohunt/homeCompany/companyTestVideo/QueryTestVideoTabbedPage'),
	),
	QUERY_TEST_DETAIL: lazy(
		() => import('../pages/presentation/evohunt/homeCompany/companyTestVideo/QueryTestDetailPage'),
	),
	TIPSTABBED: lazy(() => import('../pages/presentation/evohunt/TestTipsTabbedPage')),
	TESTVIDEOTABBED: lazy(
		() => import('../pages/presentation/evohunt/offerDetail/TestVideoCompanyTabbedPage'),
	),
	VIEWTEST: lazy(
		() => import('../pages/presentation/evohunt/offerDetail/testVideoCompany/ViewTestPage'),
	),
	VIEWCOMPLETEDTEST: lazy(
		() =>
			import(
				'../pages/presentation/evohunt/offerDetail/testVideoCompany/ViewCompletedTestPage'
			),
	),
	MESSAGGI: lazy(() => import('../pages/presentation/evohunt/messages/MessagesPage')),
	MESSAGGIO: lazy(() => import('../pages/presentation/evohunt/messages/MessagePage')),
	CHATSUPPORTO: lazy(() => import('../pages/presentation/evohunt/TestChatSupportoPage')),
	COMPANYTEAM: lazy(
		() => import('../pages/presentation/evohunt/homeCompany/team/CompanyTeamPage'),
	),
	COMPANYTEAM_DETAIL: lazy(
		() => import('../pages/presentation/evohunt/homeCompany/team/CompanyTeamDetailPage'),
	),
	COMPANY_TEAM_SKILL_DETAIL: lazy(
		() => import('../pages/presentation/evohunt/homeCompany/team/CompanyTeamSkillEmployeesPage'),
	),
	COMPANYTEAMTABBED: lazy(
		() => import('../pages/presentation/evohunt/homeCompany/companyTeam/CompanyTeamTabbedPage'),
	),
	COMPANYTESTVIDEO: lazy(
		() =>
			import(
				'../pages/presentation/evohunt/homeCompany/companyTestVideo/CompanyTestVideoPage'
			),
	),
	COMPANYTEAMPERSONALISEDSEARCH: lazy(
		() =>
			import(
				'../pages/presentation/evohunt/homeCompany/companyTeam/CompanyTeamPersonalisedSearch'
			),
	),
	COMPANYTEAMSEARCHDETAIL: lazy(
		() =>
			import('../pages/presentation/evohunt/homeCompany/companyTeam/CompanyTeamSearchDetail'),
	),
	COMPANYTEAMSEARCHDETAILPARAMETERSPAGE: lazy(
		() =>
			import(
				'../pages/presentation/evohunt/homeCompany/companyTeam/CompanyTeamSearchDetailParametersPage'
			),
	),
	COMPANYTEAMSTATISTICPAGE: lazy(
		() =>
			import(
				'../pages/presentation/evohunt/homeCompany/companyTeam/CompanyTeamStatisticPage'
			),
	),
	EVALUATECOMPETENCESPAGE: lazy(
		() =>
			import('../pages/presentation/evohunt/homeCompany/companyTeam/EvaluateCompetencesPage'),
	),
	TEAMCOMPONENTCOMPETENCEPAGE: lazy(
		() =>
			import(
				'../pages/presentation/evohunt/homeCompany/companyTeam/TeamComponentCompetencePage'
			),
	),
	TEAMCOMPONENTSTATISTICPAGE: lazy(
		() =>
			import(
				'../pages/presentation/evohunt/homeCompany/companyTeam/TeamComponentStatisticPage'
			),
	),
	TEAMSEARCHRESULTSPAGE: lazy(
		() => import('../pages/presentation/evohunt/homeCompany/companyTeam/TeamSearchResultsPage'),
	),
	EDITCOMPANYSEARCHRESULTS: lazy(
		() =>
			import(
				'../pages/presentation/evohunt/homeCompany/companyTeam/EditCompanySearchResults'
			),
	),
	ADDSTUDYPAGE: lazy(() => import('../pages/presentation/evohunt/ricerca/AddStudyPage')),
	ADDCOMPETENCEPAGE: lazy(
		() => import('../pages/presentation/evohunt/ricerca/AddCompetencePage'),
	),
	ADDEXPERIENCEPAGE: lazy(
		() => import('../pages/presentation/evohunt/ricerca/AddExperiencePage'),
	),
	ADDLANGUAGEPAGE: lazy(() => import('../pages/presentation/evohunt/ricerca/AddLanguagePage')),
	ADDSOFTSKILLPAGE: lazy(() => import('../pages/presentation/evohunt/ricerca/AddSoftSkillPage')),
	ADDCOURSEPAGE: lazy(() => import('../pages/presentation/evohunt/ricerca/AddCoursePage')),
	ADDCERTIFICATIONPAGE: lazy(() => import('../pages/presentation/evohunt/ricerca/AddCertificationPage')),

	/* TEST */
	APPLICATIONPAGE: lazy(
		() => import('../pages/presentation/evohunt/homeApplicant/ApplicationsPage'),
	),
	COMPANYMAPPAGE: lazy(() => import('../pages/presentation/evohunt/companyMap/CompanyMapPage')),
	COMPANYMAPDISTRIBUTIONDETAILPAGE: lazy(
		() => import('../pages/presentation/evohunt/companyMap/CompanyMapDistributionDetailPage'),
	),
	COMPANYMAPDISTRIBUTIONEMPLOYEESPAGE: lazy(
		() =>
			import('../pages/presentation/evohunt/companyMap/CompanyMapDistributionEmployeesPage'),
	),
	USER_DETAIL_PAGE: lazy(
		() => import('../pages/presentation/evohunt/UserDetailPage'),
	),
	STARTUPSTATISTICSPAGE: lazy(
		() => import('../pages/presentation/evohunt/startup/StartupStatisticsPage'),
	),
	IDEATORESTATISTICSPAGE: lazy(
		() => import('../pages/presentation/evohunt/ideatore/IdeatoreStatisticsPage'),
	),
	CANDIDATETESTRESULTS: lazy(
		() => import('../pages/presentation/evohunt/candidaturetestvideoeducational/testvideo/TestResultsPage'),
	),
	DATIREFERENTEDETAIL: lazy(() => import('../pages/presentation/evohunt/DatiReferenteDetail')),
	LINKAZIENDALIDETAIL: lazy(() => import('../pages/presentation/evohunt/CompanyLinkPage')),
	COMPANY_AWARDS: lazy(() => import('../pages/presentation/evohunt/CompanyAwardsPage')),
	COMPANY_SECTORS: lazy(() => import('../pages/presentation/evohunt/CompanySectorsPage')),
	COMPANY_IMAGES: lazy(() => import('../pages/presentation/evohunt/CompanyImagesPage')),
	COMPANY_LOCATIONS: lazy(() => import('../pages/presentation/evohunt/CompanyLocationsPage')),
	USER_DETAIL: lazy(() => import('../pages/presentation/evohunt/UserDetailPage')),
	USER_STATISTICS_SKILLS: lazy(() => import('../pages/presentation/evohunt/UserStatisticsSkillsPage')),
	USER_ASSESSMENT_SKILLS: lazy(() => import('../pages/presentation/evohunt/UserAssessmentSkillsPage')),
};

const presentation: RouteProps[] = [
	{
		path: evoHuntPages.evohunt.subMenu.homeCandidato.subMenu.applicationsPage.path,
		element: <EVOHUNTPAGES.APPLICATIONPAGE />,
	},
	{
		path: dashboardPagesMenu.evohunt.path,
		element: <LANDING.EVOHUNT />,
	},
	{
		path: dashboardPagesMenu.companyPreview.path,
		element: <LANDING.COMPANY_PREVIEW />,
	},
	{
		path: dashboardPagesMenu.candidatePreview.path,
		element: <LANDING.CANDIDATE_PREVIEW />,
	},
	{
		path: dashboardPagesMenu.ideaPreview.path,
		element: <LANDING.IDEA_PREVIEW />,
	},
	{
		path: evoHuntPages.evohunt.subMenu.login.path,
		element: <EVOHUNTPAGES.LOGIN />,
	},
	{
		path: evoHuntPages.evohunt.subMenu.candidatoIdeatoreSignup.path,
		element: <EVOHUNTPAGES.CANDIDATOIDEATORE />,
	},
	{
		path: evoHuntPages.evohunt.subMenu.aziendaStartupSignup.path,
		element: <EVOHUNTPAGES.AZIENDASTARTUP />,
	},
	{
		path: evoHuntPages.evohunt.subMenu.userProfile.path,
		element: <EVOHUNTPAGES.USERPROFILE />,
	},
	{
		path: evoHuntPages.evohunt.subMenu.aziendaProfileHome.path,
		element: <EVOHUNTPAGES.AZIENDAPROFILEHOME />,
	},
	{
		path: evoHuntPages.evohunt.subMenu.dettaglioCandidatoInRicerca.path,
		element: <EVOHUNTPAGES.DETTAGLIOCANDIDATOINRICERCA />,
	},
	{
		path: evoHuntPages.evohunt.subMenu.dettaglioCandidatoInRicerca.path,
		element: <EVOHUNTPAGES.DETTAGLIOCANDIDATOINRICERCA />,
	},
	{
		path: evoHuntPages.evohunt.subMenu.datiReferente.path,
		element: <EVOHUNTPAGES.DATIREFERENTE />,
	},
	{
		path: evoHuntPages.evohunt.subMenu.CuriositaPage.path,
		element: <EVOHUNTPAGES.CURIOSITAPAGE />,
	},
	{
		path: evoHuntPages.evohunt.subMenu.FaqPage.path,
		element: <EVOHUNTPAGES.FAQPAGE />,
	},
	{
		path: evoHuntPages.evohunt.subMenu.linkAziendali.path,
		element: <EVOHUNTPAGES.LINKAZIENDALI />,
	},

	{
		path: evoHuntPages.evohunt.subMenu.descrizioneAzienda.path,
		element: <EVOHUNTPAGES.DESCRIZIONEAZIENDAPAGE />,
	},
	{
		path: evoHuntPages.evohunt.subMenu.descrizioneAziendaDetail.path,
		element: <EVOHUNTPAGES.DESCRIZIONEAZIENDADETAIL />,
	},
	{
		path: evoHuntPages.evohunt.subMenu.descrizioneAzienda.path,
		element: <EVOHUNTPAGES.DESCRIZIONEAZIENDAPAGE />,
	},
	{
		path: evoHuntPages.evohunt.subMenu.scelte.path,
		element: <EVOHUNTPAGES.SCELTE />,
	},
	{
		path: evoHuntPages.evohunt.subMenu.studies.path,
		element: <EVOHUNTPAGES.STUDIES />,
	},
	{
		path: evoHuntPages.evohunt.subMenu.workingExperiences.path,
		element: <EVOHUNTPAGES.WORKINGEXPERIENCES />,
	},
	{
		path: evoHuntPages.evohunt.subMenu.languages.path,
		element: <EVOHUNTPAGES.LANGUAGES />,
	},
	{
		path: evoHuntPages.evohunt.subMenu.volounteers.path,
		element: <EVOHUNTPAGES.VOLOUNTEERS />,
	},
	{
		path: evoHuntPages.evohunt.subMenu.competenze.path,
		element: <EVOHUNTPAGES.COMPETENZE />,
	},
	{
		path: evoHuntPages.evohunt.subMenu.certifications.path,
		element: <EVOHUNTPAGES.CERTIFICATIONS />,
	},
	{
		path: evoHuntPages.evohunt.subMenu.courses.path,
		element: <EVOHUNTPAGES.COURSES />,
	},
	{
		path: evoHuntPages.evohunt.subMenu.jobsLookingFor.path,
		element: <EVOHUNTPAGES.JOBSLOOKINGFOR />,
	},
	{
		path: evoHuntPages.evohunt.subMenu.viewCompanyOffer.path,
		element: <EVOHUNTPAGES.VIEWCOMPANYOFFER />,
	},
	{
		path: evoHuntPages.evohunt.subMenu.viewIdeatorOffer.path,
		element: <EVOHUNTPAGES.VIEWIDEATOROFFER />,
	},
	{
		path: evoHuntPages.evohunt.subMenu.viewStartupOffer.path,
		element: <EVOHUNTPAGES.VIEWSTARTUPOFFER />,
	},
	{
		path: evoHuntPages.evohunt.subMenu.statsArea.path,
		element: <EVOHUNTPAGES.STATSAREA />,
	},
	{
		path: evoHuntPages.evohunt.subMenu.ideas.path,
		element: <EVOHUNTPAGES.IDEAS />,
	},
	{
		path: evoHuntPages.evohunt.subMenu.candidatureTestVideoEducational.subMenu.testVideo.subMenu
			.videoArea.path,
		element: <EVOHUNTPAGES.VIDEOAREA />,
	},
	{
		path: evoHuntPages.evohunt.subMenu.candidatureTestVideoEducational.subMenu.testVideo.subMenu
			.testArea.path,
		element: <EVOHUNTPAGES.TESTAREA />,
	},
	{
		path: evoHuntPages.evohunt.subMenu.candidatureTestVideoEducational.subMenu.testVideo.path,
		element: <EVOHUNTPAGES.TESTVIDEO />,
	},
	{
		path: evoHuntPages.evohunt.subMenu.coursesWithDetail.path,
		element: <EVOHUNTPAGES.COURSESWITHDETAIL />,
	},
	{
		path: evoHuntPages.evohunt.subMenu.courseDetail.path,
		element: <EVOHUNTPAGES.COURSEDETAIL />,
	},
	{
		path: evoHuntPages.evohunt.subMenu.advisorPage.path,
		element: <EVOHUNTPAGES.ADVISORPAGE />,
	},
	{
		path: evoHuntPages.evohunt.subMenu.advisorDetail.path,
		element: <EVOHUNTPAGES.ADVISORDETAIL />,
	},
	{
		path: evoHuntPages.evohunt.subMenu.workTrainingArea.path,
		element: <EVOHUNTPAGES.WORKTRAININGAREA />,
	},
	{
		path: evoHuntPages.evohunt.subMenu.cVAndIDArea.path,
		element: <EVOHUNTPAGES.CVANDIDAREA />,
	},
	{
		path: evoHuntPages.evohunt.subMenu.starteamArea.path,
		element: <EVOHUNTPAGES.STARTEAMAREA />,
	},
	{
		path: evoHuntPages.evohunt.subMenu.creativaArea.path,
		element: <EVOHUNTPAGES.CREATIVAAREA />,
	},
	{
		path: evoHuntPages.evohunt.subMenu.motivations.path,
		element: <EVOHUNTPAGES.MOTIVATIONS />,
	},
	{
		path: evoHuntPages.evohunt.subMenu.ideaDetail.path,
		element: <EVOHUNTPAGES.IDEADETAIL />,
	},
	{
		path: evoHuntPages.evohunt.subMenu.insertUpdate.path,
		element: <EVOHUNTPAGES.INSERTUPDATE />,
	},
	{
		path: evoHuntPages.evohunt.subMenu.testForm.path,
		element: <EVOHUNTPAGES.TEST_FORM />,
	},
	{
		path: evoHuntPages.evohunt.subMenu.savedTest.path,
		element: <EVOHUNTPAGES.SAVED_TEST />,
	},
	{
		path: evoHuntPages.evohunt.subMenu.patenti.path,
		element: <EVOHUNTPAGES.PATENTI />,
	},
	{
		path: evoHuntPages.evohunt.subMenu.hobbySports.path,
		element: <EVOHUNTPAGES.HOBBYSPORT />,
	},
	{
		path: evoHuntPages.evohunt.subMenu.hobbySports.subMenu.hobbies.path,
		element: <EVOHUNTPAGES.HOBBIES />,
	},
	{
		path: evoHuntPages.evohunt.subMenu.hobbySports.subMenu.sports.path,
		element: <EVOHUNTPAGES.SPORTS />,
	},
	{
		path: evoHuntPages.evohunt.subMenu.certificationsOpenBadge.path,
		element: <EVOHUNTPAGES.CERTIFICATIONSOPENBADGES />,
	},
	{
		path: evoHuntPages.evohunt.subMenu.certificationsOpenBadge.subMenu.openBadges.path,
		element: <EVOHUNTPAGES.OPENBADGES />,
	},
	{
		path: evoHuntPages.evohunt.subMenu.setSteps.path,
		element: <EVOHUNTPAGES.SETSTEPS />,
	},
	{
		path: evoHuntPages.evohunt.subMenu.ricerca.subMenu.ricercaConArchetipo.path,
		element: <EVOHUNTPAGES.RICERCACONARCHETIPO />,
	},
	{
		path: evoHuntPages.evohunt.subMenu.ricerca.subMenu.ricercaPersonalizzata.path,
		element: <EVOHUNTPAGES.RICERCAPERSONALIZZATA />,
	},
	{
		path: evoHuntPages.evohunt.subMenu.ricerca.path,
		element: <EVOHUNTPAGES.RICERCA />,
	},
	{
		path: evoHuntPages.evohunt.subMenu.descrizioneCandidatoTabbed.path,
		element: <EVOHUNTPAGES.DESCRIZIONECANDIDATOTABBED />
	},
	{
		path: evoHuntPages.evohunt.subMenu.descriptionProfileCandidatoDetail.path,
		element: <EVOHUNTPAGES.DESCRIPTIONPROFILECANDIDATODETAIL />,
	},
	{
		path: evoHuntPages.evohunt.subMenu.descriptionProfileCandidato.path,
		element: <EVOHUNTPAGES.DESCRIPTIONPROFILECANDIDATO />,
	},
	{
		path: evoHuntPages.evohunt.subMenu.homeCandidato.path,
		element: <EVOHUNTPAGES.HOMECANDIDATO />,
	},

	{
		path: evoHuntPages.evohunt.subMenu.homeAzienda.path,
		element: <EVOHUNTPAGES.HOMEAZIENDA />,
	},
	{
		path: evoHuntPages.evohunt.subMenu.homeIdeatore.path,
		element: <EVOHUNTPAGES.HOMEIDEATORE />,
	},
	{
		path: evoHuntPages.evohunt.subMenu.homeStartup.path,
		element: <EVOHUNTPAGES.HOMESTARTUP />,
	},
	{
		path: evoHuntPages.evohunt.subMenu.userProfile.path,
		element: <EVOHUNTPAGES.USERPROFILE />,
	},
	{
		path: evoHuntPages.evohunt.subMenu.userProfileStartup.path,
		element: <EVOHUNTPAGES.USERPROFILESTARTUP />,
	},
	{
		path: evoHuntPages.evohunt.subMenu.candidatureTestVideoEducational.subMenu.educational.path,
		element: <EVOHUNTPAGES.EDUCATIONAL />,
	},
	{
		path: evoHuntPages.evohunt.subMenu.candidatureTestVideoEducational.path,
		element: <EVOHUNTPAGES.CANDIDATURETESTVIDEOEDUCATIONAL />,
	},
	{
		path: evoHuntPages.evohunt.subMenu.softSkill.path,
		element: <EVOHUNTPAGES.SOFTSKILL />,
	},
	{
		path: evoHuntPages.evohunt.subMenu.payment.path,
		element: <EVOHUNTPAGES.PAYMENT />,
	},
	{
		path: evoHuntPages.evohunt.subMenu.ricercheArchiviate.path,
		element: <EVOHUNTPAGES.RICERCHEARCHIVIATE />,
	},
	{
		path: evoHuntPages.evohunt.subMenu.ricercheTerminate.path,
		element: <EVOHUNTPAGES.RICERCHETERMINATE />,
	},
	{
		path: evoHuntPages.evohunt.subMenu.createTeam.path,
		element: <EVOHUNTPAGES.CREATETEAM />,
	},
	{
		path: evoHuntPages.evohunt.subMenu.statistics.path,
		element: <EVOHUNTPAGES.COMPANY_STATISTICS />,
	},
	{
		path: evoHuntPages.evohunt.subMenu.planner.path,
		element: <EVOHUNTPAGES.COMPANY_PLANNER />,
	},
	{
		path: evoHuntPages.evohunt.subMenu.widget.path,
		element: <EVOHUNTPAGES.WIDGET />,
	},
	{
		path: evoHuntPages.evohunt.subMenu.compareCandidates.path,
		element: <EVOHUNTPAGES.COMPARE_CANDIDATES />,
	},
	{
		path: evoHuntPages.evohunt.subMenu.queryCandidateList.path,
		element: <EVOHUNTPAGES.QUERY_CANDIDATE_LIST />,
	},
	{
		path: evoHuntPages.evohunt.subMenu.queryDetail.path,
		element: <EVOHUNTPAGES.QUERY_DETAIL />,
	},
	{
		path: evoHuntPages.evohunt.subMenu.richiediVideoAzienda.path,
		element: <EVOHUNTPAGES.RICHIEDIVIDEO />,
	},
	{
		path: evoHuntPages.evohunt.subMenu.richiediTestAzienda.path,
		element: <EVOHUNTPAGES.RICHIEDITEST />,
	},
	{
		path: evoHuntPages.evohunt.subMenu.settings.path,
		element: <EVOHUNTPAGES.SETTINGS />,
	},
	{
		path: evoHuntPages.evohunt.subMenu.changePassword.path,
		element: <EVOHUNTPAGES.CHANGE_PASSWORD />,
	},
	{
		path: evoHuntPages.evohunt.subMenu.savedTests.path,
		element: <EVOHUNTPAGES.SAVEDTESTS />,
	},
	{
		path: evoHuntPages.evohunt.subMenu.iMieiTeam.path,
		element: <EVOHUNTPAGES.IMIEITEAM />,
	},
	{
		path: evoHuntPages.evohunt.subMenu.notifiche.path,
		element: <EVOHUNTPAGES.NOTIFICHE />,
	},
	{
		path: evoHuntPages.evohunt.subMenu.detailOfferTabbed.path,
		element: <EVOHUNTPAGES.DETAILOFFERTABBED />,
	},
	{
		path: evoHuntPages.evohunt.subMenu.queryTestVideo.path,
		element: <EVOHUNTPAGES.QUERY_TEST_VIDEO />,
	},
	{
		path: evoHuntPages.evohunt.subMenu.queryTestVideoTabbed.path,
		element: <EVOHUNTPAGES.QUERY_TEST_VIDEO_TABBED />,
	},
	{
		path: evoHuntPages.evohunt.subMenu.queryTestDetail.path,
		element: <EVOHUNTPAGES.QUERY_TEST_DETAIL />,
	},
	{
		path: evoHuntPages.evohunt.subMenu.tipsTabbed.path,
		element: <EVOHUNTPAGES.TIPSTABBED />,
	},
	{
		path: `/${evoHuntPages.evohunt.subMenu.testVideoTabbed.path}/:tab`,
		element: <EVOHUNTPAGES.TESTVIDEOTABBED />,
	},
	{
		path: evoHuntPages.evohunt.subMenu.viewTestPage.path,
		element: <EVOHUNTPAGES.VIEWTEST />,
	},
	{
		path: evoHuntPages.evohunt.subMenu.viewCompletedTestPage.path,
		element: <EVOHUNTPAGES.VIEWCOMPLETEDTEST />,
	},
	{
		path: evoHuntPages.evohunt.subMenu.messaggi.path,
		element: <EVOHUNTPAGES.MESSAGGI />,
	},
	{
		path: evoHuntPages.evohunt.subMenu.messaggio.path,
		element: <EVOHUNTPAGES.MESSAGGIO />,
	},
	{
		path: evoHuntPages.evohunt.subMenu.chatSupporto.path,
		element: <EVOHUNTPAGES.CHATSUPPORTO />,
	},
	{
		path: `/${evoHuntPages.evohunt.subMenu.companyTeamPage.path}`,
		element: <EVOHUNTPAGES.COMPANYTEAM />,
	},
	{
		path: `/${evoHuntPages.evohunt.subMenu.companyTeamDetailPage.path}`,
		element: <EVOHUNTPAGES.COMPANYTEAM_DETAIL />,
	},
	{
		path: `/${evoHuntPages.evohunt.subMenu.companyTeamSkillDetailPage.path}`,
		element: <EVOHUNTPAGES.COMPANY_TEAM_SKILL_DETAIL />,
	},
	{
		path: evoHuntPages.evohunt.subMenu.companyTestVideo.path,
		element: <EVOHUNTPAGES.COMPANYTESTVIDEO />,
	},
	{
		path: evoHuntPages.evohunt.subMenu.companyTeamPersonalisedSearch.path,
		element: <EVOHUNTPAGES.COMPANYTEAMPERSONALISEDSEARCH />,
	},
	{
		path: `/${evoHuntPages.evohunt.subMenu.companyTeamSearchDetail.path}`,
		element: <EVOHUNTPAGES.COMPANYTEAMSEARCHDETAIL idTeamSearch='' />,
	},
	{
		path: `/${evoHuntPages.evohunt.subMenu.companyTeamSearchDetailParametersPage.path}`,
		element: <EVOHUNTPAGES.COMPANYTEAMSEARCHDETAILPARAMETERSPAGE />,
	},
	{
		path: `/${evoHuntPages.evohunt.subMenu.companyTeamStatisticPage.path}`,
		element: <EVOHUNTPAGES.COMPANYTEAMSTATISTICPAGE isMobile />,
	},
	{
		path: `/${evoHuntPages.evohunt.subMenu.evaluateCompetencesPage.path}`,
		element: <EVOHUNTPAGES.EVALUATECOMPETENCESPAGE isMobile />,
	},
	{
		path: `/${evoHuntPages.evohunt.subMenu.teamComponentCompetencePage.path}`,
		element: <EVOHUNTPAGES.TEAMCOMPONENTCOMPETENCEPAGE />,
	},
	{
		path: `/${evoHuntPages.evohunt.subMenu.teamComponentStatisticPage.path}`,
		element: <EVOHUNTPAGES.TEAMCOMPONENTSTATISTICPAGE />,
	},
	{
		path: evoHuntPages.evohunt.subMenu.teamSearchResultsPage.path,
		element: <EVOHUNTPAGES.TEAMSEARCHRESULTSPAGE isMobile />,
	},
	{
		path: `/${evoHuntPages.evohunt.subMenu.editCompanySearchResults.path}`,
		element: <EVOHUNTPAGES.EDITCOMPANYSEARCHRESULTS />,
	},
	{
		path: evoHuntPages.evohunt.subMenu.ricerca.subMenu.addStudyPage.path,
		element: <EVOHUNTPAGES.ADDSTUDYPAGE />,
	},
	{
		path: evoHuntPages.evohunt.subMenu.ricerca.subMenu.addCompetencePage.path,
		element: <EVOHUNTPAGES.ADDCOMPETENCEPAGE />,
	},
	{
		path: evoHuntPages.evohunt.subMenu.ricerca.subMenu.addExperiencePage.path,
		element: <EVOHUNTPAGES.ADDEXPERIENCEPAGE />,
	},
	{
		path: evoHuntPages.evohunt.subMenu.ricerca.subMenu.addLanguagePage.path,
		element: <EVOHUNTPAGES.ADDLANGUAGEPAGE />,
	},
	{
		path: evoHuntPages.evohunt.subMenu.ricerca.subMenu.addSoftSkillPage.path,
		element: <EVOHUNTPAGES.ADDSOFTSKILLPAGE />,
	},
	{
		path: evoHuntPages.evohunt.subMenu.ricerca.subMenu.addCoursePage.path,
		element: <EVOHUNTPAGES.ADDCOURSEPAGE />,
	},
	{
		path: evoHuntPages.evohunt.subMenu.ricerca.subMenu.addCertificationPage.path,
		element: <EVOHUNTPAGES.ADDCERTIFICATIONPAGE />,
	},
	{
		path: evoHuntPages.evohunt.subMenu.companyMap.path,
		element: <EVOHUNTPAGES.COMPANYMAPPAGE />,
	},
	{
		path: evoHuntPages.evohunt.subMenu.companyMapDistributionDetail.path,
		element: <EVOHUNTPAGES.COMPANYMAPDISTRIBUTIONDETAILPAGE />,
	},
	{
		path: evoHuntPages.evohunt.subMenu.companyMapEmployeesPage.path,
		element: <EVOHUNTPAGES.COMPANYMAPDISTRIBUTIONEMPLOYEESPAGE />,
	},

	{
		path: evoHuntPages.evohunt.subMenu.userDetailPage.path,
		element: <EVOHUNTPAGES.USER_DETAIL_PAGE />,
	},
	{
		path: evoHuntPages.evohunt.subMenu.startupStatisticsPage.path,
		element: <EVOHUNTPAGES.STARTUPSTATISTICSPAGE />,
	},
	{
		path: evoHuntPages.evohunt.subMenu.ideatoreStatisticsPage.path,
		element: <EVOHUNTPAGES.IDEATORESTATISTICSPAGE />,
	},
	{
		path: evoHuntPages.evohunt.subMenu.candidatureTestVideoEducational.subMenu.testVideo.subMenu.testResults.path,
		element: <EVOHUNTPAGES.CANDIDATETESTRESULTS />,
	},
	{
		path: evoHuntPages.evohunt.subMenu.datiReferenteDetail.path,
		element: <EVOHUNTPAGES.DATIREFERENTEDETAIL />,
	},
	{
		path: evoHuntPages.evohunt.subMenu.linkAziendaliDetail.path,
		element: <EVOHUNTPAGES.LINKAZIENDALIDETAIL />,
	},
	{
		path: evoHuntPages.evohunt.subMenu.companyAwards.path,
		element: <EVOHUNTPAGES.COMPANY_AWARDS />,
	},
	{
		path: evoHuntPages.evohunt.subMenu.companySectors.path,
		element: <EVOHUNTPAGES.COMPANY_SECTORS />,
	},
	{
		path: evoHuntPages.evohunt.subMenu.companyImages.path,
		element: <EVOHUNTPAGES.COMPANY_IMAGES />,
	},
	{
		path: evoHuntPages.evohunt.subMenu.companyLocations.path,
		element: <EVOHUNTPAGES.COMPANY_LOCATIONS />,
	},
	{
		path: evoHuntPages.evohunt.subMenu.userDetail.path,
		element: <EVOHUNTPAGES.USER_DETAIL />,
	},
	{
		path: evoHuntPages.evohunt.subMenu.userStatisticsSkills.path,
		element: <EVOHUNTPAGES.USER_STATISTICS_SKILLS />,
	},
	{
		path: evoHuntPages.evohunt.subMenu.userAssessmentSkills.path,
		element: <EVOHUNTPAGES.USER_ASSESSMENT_SKILLS />,
	},
];

const contents = [...presentation];

export default contents;
