import React, { useEffect, useState } from 'react';
import { ToastProvider } from 'react-toast-notifications';
import Wrapper from '../layout/Wrapper/Wrapper';
import { Toast, ToastContainer } from '../components/bootstrap/Toasts';
import Loader from '../components/Loader';
import Portal from '../layout/Portal/Portal';
import { ReactNotifications } from 'react-notifications-component';
import { requestInterceptor, responseInterceptor } from '../interceptors/axios.interceptor';

const App = () => {
	const [loading, setLoading] = useState(false);

	const componentDidMount = (): void => {
		requestInterceptor(setLoading);
		responseInterceptor(setLoading);
	};

	useEffect((): void => {
		componentDidMount();
	}, []);

	return (
		<ToastProvider components={{ ToastContainer, Toast }}>
			{loading ? <Loader /> : null}
			<div className='app'>
				<Wrapper />
			</div>

			<Portal id='portal-notification'>
				<ReactNotifications />
			</Portal>
		</ToastProvider>
	);
};

export default App;
