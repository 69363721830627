import React, { useEffect, useState } from 'react';
import { Button, IconButton, ListItem, Typography } from '@mui/material';
import { burgerButtons } from '../../../../../menu';
import burgerMenu from '../../../../../assets/ui-icons/burgerButton-white.svg';
import blueBurgerMenu from '../../../../../assets/ui-icons/burgerbutton.svg';
import MenuButton from '../MenuButton';
import useDeviceScreen from '../../../../../hooks/useDeviceScreen';
import Dropdown, { DropdownMenu, DropdownToggle } from '../../../../bootstrap/Dropdown';
import { useNavigate } from 'react-router-dom';
import { AUTH_ROLE } from '../../../../../constants/auth.constants';

interface IReactiveDrawerMenuProps {
	iconSize?: string;
	color?: string;
}

//This component is a container for the drawer menu.
const ReactiveDrawerMenu: React.FC<IReactiveDrawerMenuProps> = ({ iconSize, color }) => {
	const { width } = useDeviceScreen();
	const [open, setOpen] = useState(false);
	const [userType, setUserType] = useState<string>('');
	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

	const [windowWidht, setWindowWidht] = useState(window.innerWidth);
	useEffect(() => {
		const handleResize = () => {
			setWindowWidht(window.innerWidth);
		};

		window.addEventListener('resize', handleResize);

		return () => {
			window.removeEventListener('resize', handleResize);
		};
	}, []);

	const navigate = useNavigate();

	const handleDrawerOpen = (event: React.MouseEvent<HTMLElement>) => {
		setOpen(true);
	};

	const handleMenuClose = () => {
		setOpen(false);
	};

	//set the user type based on the user role for the fakeuser. Make a switch for the different usertypes
	useEffect((): void => {
		setUserType(localStorage.getItem(AUTH_ROLE) ?? '');
	}, []);

	const goToPage = (page: string) => {
		navigate(`../${page}`);
		handleMenuClose();
	}

	return (
		<Dropdown isOpen={open} setIsOpen={setOpen}>
			<DropdownToggle hasIcon={false}>
				<IconButton
					aria-label='open drawer'
					className='show-icon'
					aria-haspopup='true'
					onClick={(event) => handleDrawerOpen(event)}
					edge='end'>
					{windowWidht < 769 ? (
						<MenuButton
							iconSrc={blueBurgerMenu}
							iconSize={iconSize}
							onClick={() => {}}
						/>
					) : (
						<MenuButton iconSrc={burgerMenu} iconSize={iconSize} onClick={() => {}} />
					)}
				</IconButton>
			</DropdownToggle>

			<DropdownMenu
				style={{
					position: 'absolute',
					top: 82,
					right: 0,
					display: 'flex',
					justifyContent: 'flex-end',
					flexDirection: 'column',
				}}>
				{burgerButtons(userType).map((item, index) => {
					return (
						<ListItem key={index}>
							<Button
								onClick={() => goToPage(`../${item.path}`)}
								style={{ width: '16rem' }}>
								<Typography
									variant='subtitle1'
									component='div'
									sx={{
										fontFamily: 'Poppins',
										fontWeight: '500',
										fontSize: '1rem',
									}}>
									{item.text}
								</Typography>
							</Button>
						</ListItem>
					);
				})}
			</DropdownMenu>
		</Dropdown>
	);
};

export default ReactiveDrawerMenu;
