import { createAsyncThunk, createSlice, PayloadAction, Slice } from '@reduxjs/toolkit';
import { AWARD_API, IAward } from '../common/io/orm/award';
import { axiosFindAll } from '../utils/axios';
import { BaseResponse } from '../common/io/base.response';

export interface AwardState {
	awards: IAward[];
}

const initialState: AwardState = {
	awards: [],
};

export const fetchAwards = createAsyncThunk<IAward[], void>(
    'awards/fetch',
    async (): Promise<IAward[]> => {
        const response: BaseResponse<IAward> | null = await axiosFindAll(AWARD_API, {});
        return response?.output ?? [];
    }
);

const awardSlice: Slice<AwardState> = createSlice({
    name: 'award',
    initialState,
    reducers: {},
    extraReducers: (builder): void => {
        builder
            .addCase(fetchAwards.fulfilled, (state, action: PayloadAction<IAward[]>): void => {
                state.awards = action.payload;
            });
    }
});

export default awardSlice.reducer;
