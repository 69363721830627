/**
generated on 2024-02-13 15:47:23.426259 UTC
*/

import { BaseModel } from './base';
import { IAwardTranslation } from './award-translation';
import { ISimpleItemProps } from '../../../components/evohunt/interfaces/ISimpleItemProps';
import { translateName } from '../../../utils/translation';
import { getLanguage } from '../../function/utilities';

export interface IAward extends BaseModel {

	status?: string;
	translations?: IAwardTranslation[];

}

export const AWARD_API: string = 'award';

export function convertAllAwardToSimpleItemProps(input?: IAward[]): ISimpleItemProps[] {
	const output: ISimpleItemProps[] = [];
	(input ?? []).forEach((item: IAward) => output.push(convertAwardToSimpleItemProps(item)));
	return output;
}

export function convertAwardToSimpleItemProps(input: IAward): ISimpleItemProps {
	return {
		id: input.id!.toString(),
		title: translateName(input.translations ?? [], getLanguage())
	};
}
