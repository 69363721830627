import axios, { AxiosResponse } from 'axios';
import { DOCUMENTS_URL, SERVER } from '../constants/environment.constants';
import { BaseResponse } from '../common/io/base.response';
import { BaseModel } from '../common/io/orm/base';
import { ILoginResponse } from '../common/io/login.response';
import { ICurrentUserResponse } from '../common/io/current-user.response';
import { IUsers } from '../common/io/orm/users';
import { ICompany } from '../common/io/orm/company';
import { JWT } from '../constants/auth.constants';

const ALL: string = '/find/all';
const ALL_VIEW: string = '/all-view';
const ALL_DELTA: string = ALL + '/delta';
const ALL_SUGGESTION: string = ALL + '/suggestion';
const AUTH: string = 'auth';
const AUTH_CURRENT: string = AUTH + '/current';
const AUTH_LOGIN: string = AUTH + '/login';
const AUTH_REGISTER: string = AUTH + '/register';
const AUTH_REGISTER_COMPANY: string = AUTH + '/register-company';
const COUNT: string = '/count';
const COUNT_DELTA: string = COUNT + '/delta';
const DELETE: string = '/delete-';
const INSERT: string = '/create';
const INSERT_ALL: string = INSERT + '/all';
const LAST: string = '/last';
const PARENT: string = '/parent';
const UPLOAD: string = '/upload-';

export async function axiosChangePassword(data: any): Promise<any | null> {
    const response: AxiosResponse<any, any> = await axios.put(SERVER + 'auth/change-password/' + data.id, data);
    if (HTTP_STATUS_OK.indexOf(response?.status) >= 0) {
        return response?.data;
    }
    throw new Error(response?.status?.toString());
}

export async function axiosCompanyMap<ICompanyMap>(api: string, id: number): Promise<ICompanyMap | null> {
    const response: AxiosResponse<ICompanyMap, any> = await axios.get(SERVER + api + '/map/' + id);
    if (HTTP_STATUS_OK.indexOf(response?.status) >= 0) {
        return response?.data;
    }
    throw new Error(response?.status?.toString());
}

export async function axiosCount<T extends BaseModel>(api: string, filter: T): Promise<BaseResponse<any> | null> {
    const response: AxiosResponse<BaseResponse<any>, any> = await axios.post(SERVER + api + COUNT, { body: filter });
    if (HTTP_STATUS_OK.indexOf(response?.status) >= 0) {
        return response?.data;
    }
    throw new Error(response?.status?.toString());
}

export async function axiosCountDelta<T extends BaseModel>(api: string, filter: T): Promise<BaseResponse<any> | null> {
    const response: AxiosResponse<BaseResponse<any>, any> = await axios.post(SERVER + api + COUNT_DELTA, { body: filter });
    if (HTTP_STATUS_OK.indexOf(response?.status) >= 0) {
        return response?.data;
    }
    throw new Error(response?.status?.toString());
}

export async function axiosCreate<T extends BaseModel>(api: string, data: T): Promise<T | null> {
    const response: AxiosResponse<T, any> = await axios.post(SERVER + api + INSERT, data);
    if (HTTP_STATUS_OK.indexOf(response?.status) >= 0) {
        return response?.data;
    }
    throw new Error(response?.status?.toString());
}

export async function axiosCreateList<T extends BaseModel>(api: string, data: T[]): Promise<T | null> {
    const response: AxiosResponse<T, any> = await axios.post(SERVER + api + '/list', data);
    if (HTTP_STATUS_OK.indexOf(response?.status) >= 0) {
        return response?.data;
    }
    throw new Error(response?.status?.toString());
}

export async function axiosCreateAll<T extends BaseModel>(api: string, data: T[]): Promise<BaseResponse<T> | null> {
    const response: AxiosResponse<BaseResponse<T>, any> = await axios.post(SERVER + api + INSERT_ALL, data);
    if (HTTP_STATUS_OK.indexOf(response?.status) >= 0) {
        return response?.data;
    }
    throw new Error(response?.status?.toString());
}

export async function axiosDelete(api: string, id: number): Promise<void> {
    const response: AxiosResponse<any> = await axios.delete(SERVER + api + '/delete/' + id);
    if (HTTP_STATUS_OK.indexOf(response?.status) >= 0) {
        return;
    }
    throw new Error(response?.status?.toString());
}

export async function axiosDeleteFile(api: string, type: string, id: number): Promise<void> {
    const response: AxiosResponse<any> = await axios.delete(SERVER + api + DELETE + type + '/' + id);
    if (HTTP_STATUS_OK.indexOf(response?.status) >= 0) {
        return;
    }
    throw new Error(response?.status?.toString());
}

export async function axiosDetail<T extends BaseModel>(api: string, id: number): Promise<T | null> {
    const response: AxiosResponse<T, any> = await axios.get(SERVER + api + '/detail/' + id);
    if (HTTP_STATUS_OK.indexOf(response?.status) >= 0) {
        return response?.data;
    }
    throw new Error(response?.status?.toString());
}

export async function axiosEmployees<T>(api: string, filter: any, pagination?: any, orderBy?: any): Promise<BaseResponse<T> | null> {
    const response: AxiosResponse<BaseResponse<T>, any> = await axios.post(SERVER + api + '/employees', { body: filter, pagination: pagination, orderBy: orderBy });
    if (HTTP_STATUS_OK.indexOf(response?.status) >= 0) {
        return response?.data;
    }
    throw new Error(response?.status?.toString());
}

export async function axiosFilter<T extends BaseModel>(api: string, filter: any): Promise<BaseResponse<T> | null> {
    const response: AxiosResponse<BaseResponse<T>, any> = await axios.post(SERVER + api + '/filter', { body: filter });
    if (HTTP_STATUS_OK.indexOf(response?.status) >= 0) {
        return response?.data;
    }
    throw new Error(response?.status?.toString());
}

export async function axiosFindAll<T extends BaseModel>(api: string, filter: any, pagination?: any, orderBy?: any): Promise<BaseResponse<T> | null> {
    const response: AxiosResponse<BaseResponse<T>, any> = await axios.post(SERVER + api + ALL, { body: filter, pagination: pagination, orderBy: orderBy });
    if (HTTP_STATUS_OK.indexOf(response?.status) >= 0) {
        return response?.data;
    }
    throw new Error(response?.status?.toString());
}

export async function axiosFindAllActive<T extends BaseModel>(api: string, filter: any, pagination?: any, orderBy?: any): Promise<BaseResponse<T> | null> {
    const response: AxiosResponse<BaseResponse<T>, any> = await axios.post(SERVER + api + ALL + '/active', { body: filter, pagination: pagination, orderBy: orderBy });
    if (HTTP_STATUS_OK.indexOf(response?.status) >= 0) {
        return response?.data;
    }
    throw new Error(response?.status?.toString());
}

export async function axiosFindAllArchived<T extends BaseModel>(api: string, filter: any, pagination?: any, orderBy?: any): Promise<BaseResponse<T> | null> {
    const response: AxiosResponse<BaseResponse<T>, any> = await axios.post(SERVER + api + ALL + '/archived', { body: filter, pagination: pagination, orderBy: orderBy });
    if (HTTP_STATUS_OK.indexOf(response?.status) >= 0) {
        return response?.data;
    }
    throw new Error(response?.status?.toString());
}

export async function axiosFindAllNotActive<T extends BaseModel>(api: string, filter: any, pagination?: any, orderBy?: any): Promise<BaseResponse<T> | null> {
    const response: AxiosResponse<BaseResponse<T>, any> = await axios.post(SERVER + api + ALL + '/not-active', { body: filter, pagination: pagination, orderBy: orderBy });
    if (HTTP_STATUS_OK.indexOf(response?.status) >= 0) {
        return response?.data;
    }
    throw new Error(response?.status?.toString());
}

export async function axiosFindAllByRole<T extends BaseModel>(api: string, role: string, filter: any, pagination?: any, orderBy?: any): Promise<BaseResponse<T> | null> {
    const response: AxiosResponse<BaseResponse<T>, any> = await axios.post(SERVER + api + ALL + '/' + role, { body: filter, pagination: pagination, orderBy: orderBy });
    if (HTTP_STATUS_OK.indexOf(response?.status) >= 0) {
        return response?.data;
    }
    throw new Error(response?.status?.toString());
}

export async function axiosFindAllWithParticipant<T extends BaseModel>(api: string, filter: any, participant: number, pagination?: any, orderBy?: any): Promise<BaseResponse<T> | null> {
    const response: AxiosResponse<BaseResponse<T>, any> = await axios.post(SERVER + api + ALL + '/' + participant, { body: filter, pagination: pagination, orderBy: orderBy });
    if (HTTP_STATUS_OK.indexOf(response?.status) >= 0) {
        return response?.data;
    }
    throw new Error(response?.status?.toString());
}

export async function axiosFindAllAndView<T extends BaseModel>(api: string, filter: any, pagination?: any, orderBy?: any): Promise<BaseResponse<T> | null> {
    const response: AxiosResponse<BaseResponse<T>, any> = await axios.post(SERVER + api + ALL_VIEW, { body: filter, pagination: pagination, orderBy: orderBy });
    if (HTTP_STATUS_OK.indexOf(response?.status) >= 0) {
        return response?.data;
    }
    throw new Error(response?.status?.toString());
}

export async function axiosFindAllDelta<T extends BaseModel>(api: string, filter: any, pagination?: any): Promise<BaseResponse<T> | null> {
    const response: AxiosResponse<BaseResponse<T>, any> = await axios.post(SERVER + api + ALL_DELTA, { body: filter, pagination: pagination });
    if (HTTP_STATUS_OK.indexOf(response?.status) >= 0) {
        return response?.data;
    }
    throw new Error(response?.status?.toString());
}

export async function axiosFindAllSuggestion<T extends BaseModel>(api: string, filter: any): Promise<BaseResponse<T> | null> {
    const response: AxiosResponse<BaseResponse<T>, any> = await axios.post(SERVER + api + ALL_SUGGESTION, { body: filter });
    if (HTTP_STATUS_OK.indexOf(response?.status) >= 0) {
        return response?.data;
    }
    throw new Error(response?.status?.toString());
}

export async function axiosFindLast<T extends BaseModel>(api: string, id: number): Promise<T | null> {
    const response: AxiosResponse<T, any> = await axios.get(SERVER + api + '/' + id + LAST);
    if (HTTP_STATUS_OK.indexOf(response?.status) >= 0) {
        return response?.data;
    }
    throw new Error(response?.status?.toString());
}

export async function axiosFindParent<T extends BaseModel>(api: string, filter: any, pagination?: any): Promise<BaseResponse<T> | null> {
    const response: AxiosResponse<BaseResponse<T>, any> = await axios.post(SERVER + api + PARENT, { body: filter, pagination: pagination });
    if (HTTP_STATUS_OK.indexOf(response?.status) >= 0) {
        return response?.data;
    }
    throw new Error(response?.status?.toString());
}

export async function axiosFindById<T extends BaseModel>(api: string, id: number): Promise<T | null> {
    const response: AxiosResponse<T, any> = await axios.get(SERVER + api + '/find/' + id);
    if (HTTP_STATUS_OK.indexOf(response?.status) >= 0) {
        return response?.data;
    }
    throw new Error(response?.status?.toString());
}

export async function axiosStatisticsById<T extends BaseModel>(api: string, id: number): Promise<T | null> {
    const response: AxiosResponse<T, any> = await axios.get(SERVER + api + '/statistics/' + id);
    if (HTTP_STATUS_OK.indexOf(response?.status) >= 0) {
        return response?.data;
    }
    throw new Error(response?.status?.toString());
}

export async function axiosFindEmployeesBySkill<T extends BaseModel>(api: string, idTeam: number, idSkill: number): Promise<BaseResponse<T> | null> {
    const response: AxiosResponse<BaseResponse<T>, any> = await axios.get(SERVER + api + '/' + idTeam + '/skill/' + idSkill);
    if (HTTP_STATUS_OK.indexOf(response?.status) >= 0) {
        return response?.data;
    }
    throw new Error(response?.status?.toString());
}

export async function axiosFindByIdAndView<T extends BaseModel>(api: string, id: number): Promise<T | null> {
    const response: AxiosResponse<T, any> = await axios.get(SERVER + api + '/' + id + '/view');
    if (HTTP_STATUS_OK.indexOf(response?.status) >= 0) {
        return response?.data;
    }
    throw new Error(response?.status?.toString());
}

export async function axiosFindByUuid<T extends BaseModel>(api: string, uuid: string): Promise<T | null> {
    const response: AxiosResponse<T, any> = await axios.get(SERVER + api + '/uuid/' + uuid);
    if (HTTP_STATUS_OK.indexOf(response?.status) >= 0) {
        return response?.data;
    }
    throw new Error(response?.status?.toString());
}

export async function axiosFindByUserId<T extends BaseModel>(api: string, id: number): Promise<T | null> {
    const response: AxiosResponse<T, any> = await axios.get(SERVER + api + '/by-user/' + id);
    if (HTTP_STATUS_OK.indexOf(response?.status) >= 0) {
        return response?.data;
    }
    throw new Error(response?.status?.toString());
}

export async function axiosGenerateEHCv<T>(id: number): Promise<T | null> {
    const response: AxiosResponse<T, any> = await axios.get(SERVER + 'download/cv/' + id);
    if (HTTP_STATUS_OK.indexOf(response?.status) >= 0) {
        return response?.data;
    }
    throw new Error(response?.status?.toString());
}

export async function axiosInit<T extends BaseModel>(api: string, data: T): Promise<T | null> {
    const response: AxiosResponse<T, any> = await axios.post(SERVER + api + '/init', data);
    if (HTTP_STATUS_OK.indexOf(response?.status) >= 0) {
        return response?.data;
    }
    throw new Error(response?.status?.toString());
}

export async function axiosLoggedUser(): Promise<IUsers | null> {
    const response: AxiosResponse<ICurrentUserResponse, any> = await axios.get(SERVER + AUTH_CURRENT);
    if (HTTP_STATUS_OK.indexOf(response?.status) >= 0) {
        return response?.data?.current;
    }
    throw new Error(response?.status?.toString());
}

export async function axiosLogin(username: string, password: string): Promise<ILoginResponse | null> {
    const response: AxiosResponse<ILoginResponse, any> = await axios.post(SERVER + AUTH_LOGIN, { username, password });
    if (response?.data?.accessToken) {
        localStorage.setItem(JWT, response?.data?.accessToken);
    }
    return response?.data;
}

export async function axiosRegister(user: IUsers): Promise<void> {
    await axios.post(SERVER + AUTH_REGISTER, user);
}

export async function axiosRegisterCompany(company: ICompany): Promise<void> {
    await axios.post(SERVER + AUTH_REGISTER_COMPANY, company);
}

export async function axiosUpdate<T extends BaseModel>(api: string, data: T): Promise<T | null> {
    const response: AxiosResponse<T, any> = await axios.put(SERVER + api + '/update/' + data.id, data);
    if (HTTP_STATUS_OK.indexOf(response?.status) >= 0) {
        return response?.data;
    }
    throw new Error(response?.status?.toString());
}

export async function axiosUploadFile<T extends BaseModel>(api: string, file: File, type: string, id: number, name?: string): Promise<T | null> {
    const formData: FormData = new FormData();
    formData.append('file', file);
    const response: AxiosResponse<T, any> = await axios.post(SERVER + api + UPLOAD + type + '/' + id + (name ? '/' + name : ''), formData);
    if (HTTP_STATUS_OK.indexOf(response?.status) >= 0) {
        return response?.data;
    }
    throw new Error(response?.status?.toString());
}

export function axiosDownloadFile(filePath: string, fileName?: string): void {
    const url: string = DOCUMENTS_URL + filePath;
    const link: HTMLAnchorElement = document.createElement("a");
    link.href = url;
    link.download = fileName ?? filePath;
    link.target = '_blank';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
}

export async function axiosWidget<ICompany>(api: string, id: number): Promise<ICompany | null> {
    const response: AxiosResponse<ICompany, any> = await axios.put(SERVER + api + '/' + id + '/widget');
    if (HTTP_STATUS_OK.indexOf(response?.status) >= 0) {
        return response?.data;
    }
    throw new Error(response?.status?.toString());
}

export async function axiosHide<INotification>(api: string, id: number): Promise<INotification | null> {
    const response: AxiosResponse<INotification, any> = await axios.put(SERVER + api + '/' + id + '/hide');
    if (HTTP_STATUS_OK.indexOf(response?.status) >= 0) {
        return response?.data;
    }
    throw new Error(response?.status?.toString());
}

const HTTP_STATUS_OK: number[] = [
    200, 201, 202, 203
];
