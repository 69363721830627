import axios, { AxiosResponse, InternalAxiosRequestConfig } from 'axios';
import { consoleError } from '../log';
import showNotification from '../components/extras/showNotification';
import React from 'react';
import { t } from 'i18next';
import { v4 as uuidv4 } from 'uuid';
import { getLoggedUserId } from '../common/function/utilities';
import { jwtDecode } from 'jwt-decode';
import { JWT } from '../constants/auth.constants';
import {
	CACHE_CONTROL,
	CACHE_CONTROL_VALUE,
	CLIENT_ID as CLIENT_ID,
	CONTENT_TYPE,
	CONTENT_TYPE_APPLICATION_JSON,
	CONTENT_TYPE_MULTIPART_FORM_DATA,
	EXPIRES,
	EXPIRES_VALUE,
	LANGUAGE as LANGUAGE,
	PRAGMA,
	PRAGMA_VALUE,
	X_REQUEST_ID,
} from '../constants/headers.constants';
import {
	CLIENT_ID as LS_CLIENT_ID,
	LANGUAGE as LS_LANGUAGE,
} from '../constants/localstorage.constants';

const isTokenExpired = (token: string): boolean => {
	if (!token) return true;
	try {
		const decodedToken: any = jwtDecode(token);
		const currentTime: number = Date.now() / 1000;
		return decodedToken.exp < currentTime;
	} catch (error) {
		console.error('Error decoding token:', error);
		return true;
	}
};

const handleError = (setLoading: Function, error: any): void => {
    setLoading(false);

    if (error) {
        if (error === 'TOKEN_EXPIRED') {
            showNotification(<></>, <h5>Token expired</h5>, 'danger');
            localStorage.clear();
            return;
        }

        if (!error.response) {
            showNotification(<></>, <h5>Error</h5>, 'danger');
        } else if (error.response?.status === 401) {
            showNotification(<></>, <h5>{t('error.unauthorized')}</h5>, 'danger');
        } else if (error.response?.status === 404) {
            showNotification(<></>, <h5>{t('error.not-found')}</h5>, 'danger');
        } else {
            showNotification(<></>, <h5>{t('error.general')}</h5>, 'danger');
        }

        if (error.response) {
            consoleError('Error ' + error.response.status + ' ' + error.response.statusText + ' =============> ', error.response);
        }
    }
};

export function requestInterceptor(setLoading: Function): void {
    axios.interceptors.request.use(
        (config: InternalAxiosRequestConfig) => {
            setLoading(true);

            const accessToken: string | null = localStorage.getItem(JWT);
            if (accessToken) {
                if (isTokenExpired(accessToken)) {
                    handleError(setLoading, 'TOKEN_EXPIRED');
                    return Promise.reject('TOKEN_EXPIRED');
                }
                config.headers.Authorization = `Bearer ${accessToken}`;
            }

            const language: string | null = localStorage.getItem(LS_LANGUAGE);
            if (language) {
                config.headers[LANGUAGE] = language;
            }

            if (config.data instanceof FormData) {
                config.headers[CONTENT_TYPE] = CONTENT_TYPE_MULTIPART_FORM_DATA;
            } else {
                config.headers[CONTENT_TYPE] = CONTENT_TYPE_APPLICATION_JSON;
            }

            config.headers[CACHE_CONTROL] = CACHE_CONTROL_VALUE
            config.headers[PRAGMA] = PRAGMA_VALUE;
            config.headers[EXPIRES] = EXPIRES_VALUE;

            let clientId: string | null = sessionStorage.getItem(LS_CLIENT_ID);
            if (!clientId) {
                clientId = (getLoggedUserId() ? getLoggedUserId() + "_" : "") + uuidv4();
                sessionStorage.setItem(LS_CLIENT_ID, clientId);
            }
            config.headers[CLIENT_ID] = clientId;
            config.headers[X_REQUEST_ID] = uuidv4();

            return config;
        },
        (err: any): Promise<never> => {
            handleError(setLoading, err);
            return Promise.reject(err);
        }
    );
}

export function responseInterceptor(setLoading: Function): void {
    axios.interceptors.response.use(
        (response: AxiosResponse<any, any>) => {
            setLoading(false);
            return response;
        },
        (err: any): Promise<never> => {
            handleError(setLoading, err);
            return Promise.reject(err);
        }
    );
}
