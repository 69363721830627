import { combineReducers, configureStore } from '@reduxjs/toolkit';
import storage from 'localforage';
import { persistReducer, persistStore } from 'redux-persist';

import awardReducer, { AwardState } from './awardSlice';
import { Persistor } from 'redux-persist/es/types';

const persistConfig = {
	key: 'root',
	storage,
	whitelist: ['award'],
};

const rootReducer = combineReducers({
	award: awardReducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export interface IStore {
	award: AwardState,
}

export const store = configureStore({
	reducer: persistedReducer,
	middleware: (getDefaultMiddleware) => getDefaultMiddleware({serializableCheck: false})
});

export const persistor: Persistor = persistStore(store);
export type AppDispatch = typeof store.dispatch;
