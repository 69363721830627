import { LOG_LEVEL } from './constants/environment.constants';
import { LOG_LEVEL_INFO } from './constants/log.constants';

export function consoleDir(message: string, object: any) {
	if (LOG_LEVEL === LOG_LEVEL_INFO) {
		console.log(message);
		console.dir(object);
	}
}
export function consoleLog(message: string) {
    if (LOG_LEVEL === LOG_LEVEL_INFO) {
        console.log(message);
    }
}
export function consoleWarn(message: string) {
    if (LOG_LEVEL === LOG_LEVEL_INFO) {
        console.warn(message);
    }
}
export function consoleError(message: string, object: any) {
    if (LOG_LEVEL === LOG_LEVEL_INFO) {
        console.error(message);
        console.error(object);
    }
}
