import React, { useEffect, useState } from 'react';
import {
	AdvisorIconBlue,
	AdvisorIconGray,
	ApplicantIconBlue,
	ApplicantIconGray,
	HomeIconBlue,
	HomeIconGray,
	IdeatoreIconBlue,
	IdeatoreIconGray,
	MessageIconBlue,
	MessageIconGray,
	NotificationIconBlue,
	NotificationIconGray,
	ProfileImgIcon,
} from '../../../assets/ui-icons/header-icon';
import MenuButton from '../../../components/evohunt/common/ui/MenuButton';
import { dashboardPagesMenu, evoHuntPages } from '../../../menu';
import { useLocation, useNavigate } from 'react-router-dom';
import {
	getLoggedAvatar,
	getLoggedUserId,
	getLoggedUserRole,
} from '../../../common/function/utilities';
import { ROLE } from '../../../constants/constants';
import { IMAGES_URL } from '../../../constants/environment.constants';

const DefaultFooter = () => {
	const [active, setActive] = useState('');
	const navigate = useNavigate();
	const { pathname } = useLocation();

	useEffect(() => {
		const path: string = window.location.pathname;
		const active: string = path.split('/').pop() || '';

		setActive(active);
	}, []);

	const handleHomeClick = () => {
		navigate(`../${dashboardPagesMenu.evohunt.path}`);
		setActive('homeAzienda');
	};

	const handleMessagesClick = () => {
		navigate(`../${evoHuntPages.evohunt.subMenu.messaggi.path}`);
		setActive('messaggi');
	};

	const handleNotificationClick = () => {
		navigate(`../${evoHuntPages.evohunt.subMenu.notifiche.path}`);
		setActive('notifiche');
	};

	const handleAdvisorClick = () => {
		navigate(`../${evoHuntPages.evohunt.subMenu.tipsTabbed.path}`);
		setActive('advisor');
	};

	const imgProfileClickHandler = () => {
		setActive('profilo');
		if (getLoggedUserRole() === ROLE.USER) {
			navigate(`../${evoHuntPages.evohunt.subMenu.userProfile.path}`);
		} else if (getLoggedUserRole() === ROLE.COMPANY) {
			navigate(`../${evoHuntPages.evohunt.subMenu.aziendaProfileHome.path}`);
		} else if (getLoggedUserRole() === ROLE.IDEATOR) {
			navigate(`../${evoHuntPages.evohunt.subMenu.userProfile.path}`);
		} else if (getLoggedUserRole() === ROLE.STARTUP) {
			navigate(`../${evoHuntPages.evohunt.subMenu.userProfileStartup.path}`);
		}
	};

	const switchUserRoleIcon = () => {
		switch (getLoggedUserRole()) {
			case ROLE.USER:
				return (
					<MenuButton
						iconSrc={active === 'advisor' ? ApplicantIconBlue : ApplicantIconGray}
						onClick={handleAdvisorClick}
						iconSize='3rem'
					/>
				);
			case ROLE.IDEATOR:
				return (
					<MenuButton
						iconSrc={active === 'advisor' ? IdeatoreIconBlue : IdeatoreIconGray}
						onClick={handleAdvisorClick}
						iconSize='3rem'
					/>
				);
			default:
				return (
					<MenuButton
						iconSrc={active === 'advisor' ? AdvisorIconBlue : AdvisorIconGray}
						onClick={handleAdvisorClick}
						iconSize='3rem'
					/>
				);
		}
	};

	return (
		<>
			{getLoggedUserId() && (
				<footer
					className={`${pathname === '/' ? 'element-display-none' : 'footer-container'}`}>
					<div className='footer-btn'>
						<hr
							className={`${
								active === 'homeAzienda' ? 'footer-hr-active' : 'footer-hr '
							}`}
						/>
						<MenuButton
							iconSrc={active === 'homeAzienda' ? HomeIconBlue : HomeIconGray}
							onClick={handleHomeClick}
							iconSize='3rem'
						/>
					</div>

					<div className='footer-btn'>
						<hr
							className={`${
								active === 'messaggi' ? 'footer-hr-active' : 'footer-hr '
							}`}
						/>
						<MenuButton
							iconSrc={active === 'messaggi' ? MessageIconBlue : MessageIconGray}
							onClick={handleMessagesClick}
							iconSize='3rem'
						/>
					</div>

					<div className='footer-btn'>
						<hr
							className={`${
								active === 'notifiche' ? 'footer-hr-active' : 'footer-hr '
							}`}
						/>
						<MenuButton
							iconSrc={
								active === 'notifiche' ? NotificationIconBlue : NotificationIconGray
							}
							onClick={handleNotificationClick}
							iconSize='3rem'
						/>
					</div>

					<div className='footer-btn'>
						<hr
							className={`${
								active === 'advisor' ? 'footer-hr-active' : 'footer-hr '
							}`}
						/>
						{switchUserRoleIcon()}
					</div>

					<div className='footer-btn'>
						<hr
							className={`${
								active === 'profilo' ? 'footer-hr-active' : 'footer-hr '
							}`}
						/>
						<MenuButton
							iconSrc={
								getLoggedAvatar() ? IMAGES_URL + getLoggedAvatar() : ProfileImgIcon
							}
							onClick={imgProfileClickHandler}
							iconSize='3rem'
							circular={true}
						/>
					</div>
				</footer>
			)}
		</>
	);
};

export default DefaultFooter;
